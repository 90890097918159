import ProductCollection from '../collections/ProductCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class ProductSection extends BaseModel {
    type: ModelType = ModelType.PRODUCT_SECTIONS;
    static type: ModelType = ModelType.PRODUCT_SECTIONS;
    prefix = 'inventory:product-section';
    static prefix = 'inventory:product-section';

    get relationshipsMap(): Record<string, ModelType> {
        return {
            products: ModelType.PRODUCTS,
        };
    }

    get products(): ProductCollection | null {
        return this.relationships?.products?.data || null;
    }

    get title(): string {
        return this.attributes.title;
    }

    get defaultQuantity(): number {
        return this.attributes.defaultQuantity;
    }

    get productsCount(): number {
        return this.relationships.products?.meta?.count || 0;
    }
}
