import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { Meta } from 'zhead';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';
import ContactCollection from '../collections/ContactCollection';
import Contact from '../models/Contact';

export default class ContactEndpoint extends BaseEndpoint<Contact, ContactCollection> {
    model: ModelType = ModelType.CONTACTS;
    path: string = 'tenant/contact/contacts';
}
