import { Meta } from 'zhead';
import WorksiteProfitabilityDocumentCollection from '../collections/WorksiteProfitabilityDocumentCollection';
import WorksiteProfitabilityDocument from '../models/WorksiteProfitabilityDocument';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';

export default class WorksiteProfitabilityDocumentEndpoint extends BaseEndpoint<WorksiteProfitabilityDocument, WorksiteProfitabilityDocumentCollection> {
    model: ModelType = ModelType.WORKSITE_PROFITABILITY_DOCUMENTS;
    path = 'tenant/worksite/worksite-profitability-documents';
    include = 'invoice,expense,timeActivity,expense.pdf';
}
