const useLocalStorage = <TValue>(key: string) => {
    let stringified = false;
    const setValue = (value: TValue): void => {
        stringified = typeof value === 'object';
        window.localStorage.setItem(key, stringified ? JSON.stringify(value) : `${value}`);
    };
    const getValue = (): TValue | null => {
        const value = window.localStorage.getItem(key);
        if (!value) return null;
        try {
            return JSON.parse(value);
        } catch (e) {
            return value as TValue;
        }
    };
    const resetValue = (): void => {
        window.localStorage.removeItem(key);
    };

    return { getValue, setValue, resetValue };
};

export default useLocalStorage;
