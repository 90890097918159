import { ModelType } from '../base/schemas/BaseSchema';
import ModelFactory from './ModelFactory';

export default class ModelPrefix {
    static get(model: ModelType): string {
        let modelClass = ModelFactory.getClass(model);
        return modelClass.prefix;
    }

    static reverse(prefix: string): ModelType {
        let modelClass = ModelFactory.getClassByPrefix(prefix);
        return modelClass.type;
    }
}
