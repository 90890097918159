import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class InvoicingNotificationsSetting extends BaseModel {
    type: ModelType = ModelType.INVOICING_NOTIFICATIONS_SETTINGS;
    static type: ModelType = ModelType.INVOICING_NOTIFICATIONS_SETTINGS;
    prefix: string = 'invoicing:notifications-setting';
    static prefix: string = 'invoicing:notifications-setting';

    get documentType(): string {
        return this.attributes.documentType;
    }

    get emailSubject(): TranslatedField {
        return this.attributes.emailSubject;
    }

    get emailText(): TranslatedField {
        return this.attributes.emailText;
    }

    get copy(): boolean {
        return this.attributes.copy == 1;
    }

    get sms(): TranslatedField {
        return this.attributes.sms;
    }

    get letterTitle(): TranslatedField {
        return this.attributes.letterTitle;
    }

    get letterText(): TranslatedField {
        return this.attributes.letterText;
    }
}
