import TaskCollection from '../collections/TaskCollection';
import Task from '../models/Task';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import moment from '~~/utils/moment';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import TaskSchema from '../schemas/TaskSchema';
import { ModelEndpointResponse } from '@/app/base/endpoints/types';

export default class TaskEndpoint extends BaseEndpoint<Task, TaskCollection> {
    model: ModelType = ModelType.TASKS;
    path = 'tenant/task/tasks';
    include = 'users,contact,lead,worksite,worksitePhase,createdBy,completedBy';

    async toggleCompletedAt(task: Task): Promise<ModelEndpointResponse<Task>> {
        const { authed } = useAuthStore();
        task.completedAt = task.isCompleted ? null : moment();
        useEvent('task:task:updated', task);

        const schema = new TaskSchema({
            id: task.getId(),
            attributes: {
                completedAt: task.completedAt?.toUtcDateTimeString() || null,
            },
        });

        if (task.completedAt && authed) {
            schema.addToOneRelationship('completedBy', ModelType.USERS, authed.getId());
        }

        return await this.update(schema);
    }
}
