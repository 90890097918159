import qs from 'qs';

export default class AddressImage {
    static make(address: Address): string {
        let base = 'https://maps.googleapis.com/maps/api/staticmap';
        let parameters = qs.stringify({
            center: address.display,
            size: '600x300',
            zoom: 19,
            maptype: 'satellite',
            key: 'AIzaSyCWRwknrBd-sqJ46j37jeqn1G7WbFPxvaE',
        });

        return `${base}?${parameters}`;
    }
}
