import Lead from '../models/Lead';
import LeadStatus from '../enums/LeadStatus';
import BaseCollection from '@/app/base/collections/BaseCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import moment from '~~/utils/moment';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import User from '~~/app/auth/models/User';

export default class LeadCollection extends BaseCollection<Lead> {
    model: ModelType = ModelType.LEADS;

    byStatus(status: string): Lead[] {
        return this.filter((lead: Lead) => lead.hasStatus(status));
    }
}
