import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class Setting extends BaseModel {
    type: ModelType = ModelType.SETTINGS;
    static type: ModelType = ModelType.SETTINGS;
    prefix: string = 'common:settings';
    static prefix: string = 'common:settings';

    get key(): string {
        return this.attributes.key;
    }

    get value() {
        return this.meta?.value;
    }

    get rawValue() {
        return this.meta?.rawValue;
    }

    get cast(): string {
        return this.attributes.cast;
    }
}
