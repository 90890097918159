import { Meta } from 'zhead';
import WorkfieldCollection from '../collections/WorkfieldCollection';
import Workfield from '../models/Workfield';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';
import Worksite from '~~/app/worksite/models/Worksite';

export default class WorkfieldEndpoint extends BaseEndpoint<Workfield, WorkfieldCollection> {
    model: ModelType = ModelType.WORKFIELDS;
    path = 'common/workfields';
}
