import TimeActivity from './TimeActivity';
import BaseModel from '~~/app/base/models/BaseModel';
import User from '~~/app/auth/models/User';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class TimeActivitySetting extends BaseModel {
    type: ModelType = ModelType.TIME_ACTIVITY_SETTINGS;
    static type: ModelType = ModelType.TIME_ACTIVITY_SETTINGS;
    prefix = 'planning:time-activity-settings';
    static prefix = 'planning:time-activity-settings';

    get relatedTo(): User | TimeActivity | null {
        return this.relationships?.relatedTo?.data;
    }

    get maxEndsAt(): string {
        return this.attributes?.maxEndsAt;
    }

    get relatedToId(): string | null {
        return this.relatedTo?.id;
    }

    get relatedToType(): string | null {
        return this.relatedTo?.type;
    }

    get isActivated(): boolean {
        return this.attributes.isActivated;
    }
}
