import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class InvoicingDocumentsSetting extends BaseModel {
    type: ModelType = ModelType.INVOICING_DOCUMENTS_SETTINGS;
    static type: ModelType = ModelType.INVOICING_DOCUMENTS_SETTINGS;
    prefix: string = 'invoicing:documents-setting';
    static prefix: string = 'invoicing:documents-setting';

    get documentType(): string {
        return this.attributes.documentType;
    }

    get dueDays(): number {
        return this.attributes.dueDays;
    }

    get conditions(): TranslatedField {
        return this.attributes.conditions;
    }
}
