import Worksite from './Worksite';
import User from '~~/app/auth/models/User';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import Media from '~~/app/common/models/Media';
import moment from '~~/utils/moment';
import TaskCollection from '~~/app/task/collections/TaskCollection';
import Task from '~~/app/task/models/Task';
import CalendarEventCollection from '~~/app/planning/collections/CalendarEventCollection';

export default class WorksitePhase extends BaseModel {
    type: ModelType = ModelType.WORKSITE_PHASES;
    static type: ModelType = ModelType.WORKSITE_PHASES;
    prefix = 'worksite:worksite-phase';
    static prefix = 'worksite:worksite-phase';

    get relationshipsMap(): Record<string, ModelType> {
        return {
            worksite: ModelType.WORKSITES,
            tasks: ModelType.TASKS,
        };
    }

    relationshipsMapped(): void {
        if (this.relationships.tasks && this.relationships.tasks.data && typeof this.relationships.tasks.data.isModelOrCollection === 'function') {
            this.relationships.tasks.data.map((task: Task) => task.setRelationship('worksitePhase', this.cloneWithoutRelationships(ModelType.WORKSITE_PHASES)));
            this.relationships.tasks.data.setItemShouldBeInCollectionCallback((task: Task) => task.worksitePhase?.getId() === this.getId());
        }
    }

    get worksite(): Worksite | null {
        return this.relationships?.worksite?.data || null;
    }

    get tasks(): TaskCollection | null {
        return this.relationships?.tasks?.data || null;
    }

    get calendarEvents(): CalendarEventCollection | null {
        return this.relationships?.calendarEvents?.data || null;
    }

    get title(): string {
        return this.attributes.title;
    }

    get startsAt(): moment.Moment {
        return moment(this.attributes.startsAt);
    }

    get endsAt(): moment.Moment {
        return moment(this.attributes.endsAt);
    }

    get tasksTimeActivitiesTotalDuration(): moment.Duration | null {
        if (!this.tasks || this.tasks.isEmpty()) {
            return null;
        }

        return moment.duration(this.tasks.sum('timeActivitiesTotalDurationRaw'), 'seconds');
    }
}
