import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type ModelFileRelationships = {
    model: ToOneRelationship<ModelType.WORKSITES> | ToOneRelationship<ModelType.INVOICES> | ToOneRelationship<ModelType.LEADS> | ToOneRelationship<ModelType.INVOICE_TEMPLATES>;
    modelFileFolder: ToOneRelationship<ModelType.MODEL_FILE_FOLDERS>;
    user: ToOneRelationship<ModelType.USERS>;
};

type ModelFileAttributes = {
    title: string;
    description: string;
    mediaId: string;
    mediaSize: number;
    mediaType: string;
};

type ModelFileMeta = {
    media: MediaInterface | null;
};

export default class ModelFileSchema extends BaseSchema<ModelFileAttributes, ModelFileMeta, ModelFileRelationships> {
    protected type: ModelType = ModelType.MODEL_FILES;
}
