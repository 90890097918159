import CalendarEvent from '../models/CalendarEvent';
import BaseCollection from '@/app/base/collections/BaseCollection';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import User from '~~/app/auth/models/User';
import moment from '~~/utils/moment';

export default class CalendarEventCollection extends BaseCollection<CalendarEvent> {
    model: ModelType = ModelType.CALENDAR_EVENTS;

    whereHasUser(user: User): Array<CalendarEvent> {
        return this.filter((event: CalendarEvent) => event.hasUser(user));
    }

    whereHasUserAndDate(user: User, date: moment.Moment): Array<CalendarEvent> {
        return this.filter((event: CalendarEvent) => event.hasUser(user) && event.startsAt?.isSame(date, 'day'));
    }

    toFullCalendarEvents(): Array<any> {
        return this.map((event: CalendarEvent) => event.toFullCalendarEvent());
    }
}
