import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class Source extends BaseModel {
    type: ModelType = ModelType.SOURCES;
    static type: ModelType = ModelType.SOURCES;
    prefix = 'crm:source';
    static prefix = 'crm:source';

    get relationshipsMap(): Record<string, ModelType> {
        return {};
    }

    get title(): string {
        return this.attributes.title;
    }
}
