import { ModelType } from '@/app/base/schemas/BaseSchema';
import InvoiceTemplateCollection from '../collections/InvoiceTemplateCollection';
import InvoiceTemplate from '../models/InvoiceTemplate';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelEndpointResponse } from '@/app/base/endpoints/types';

type InvoiceTemplatePreview = {
    data: {
        type: 'invoice-template-previews';
        id: string;
        attributes: { html: string };
    };
};

export default class InvoiceTemplateEndpoint extends BaseEndpoint<InvoiceTemplate, InvoiceTemplateCollection> {
    model: ModelType = ModelType.INVOICE_TEMPLATES;
    path = 'tenant/invoicing/invoice-templates';

    async preview(uuid: string): Promise<InvoiceTemplatePreview> {
        const response = await this.requestRaw({
            path: `${uuid}/-actions/preview`,
            method: 'GET',
        });
        return (await response.json()) as InvoiceTemplatePreview;
    }

    async fromPreset(preset: string): Promise<ModelEndpointResponse<InvoiceTemplate>> {
        const response = await this.customAction(null, 'from-preset', 'POST', {
            data: {
                attributes: { preset },
                type: 'invoice-templates',
            },
        });

        if (response.data) {
            this.sendEvent('created', response.data);
        }

        return response;
    }
}
