import BankAccount from '../models/BankAccount';
import BankAccountCollection from '../collections/BankAccountCollection';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

type BankAccountAuthorization = {
    data: {
        type: 'bankaccount-bank-accounts-authorization';
        id: string;
        attributes: { url: string };
    };
};

export default class BankAccountEndpoint extends BaseEndpoint<BankAccount, BankAccountCollection> {
    model: ModelType = ModelType.BANK_ACCOUNTS;
    path = 'tenant/bankaccount/bank-accounts';

    public async authorize() {
        try {
            const response = await this.requestRaw({
                path: '-actions/authorize',
                method: 'GET',
            });
            return (await response.json()) as BankAccountAuthorization;
        } catch (error) {}
    }
}
