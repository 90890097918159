import { Str } from '@supercharge/strings/dist';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class InvoicingPaymentRemindersSetting extends BaseModel {
    type: ModelType = ModelType.INVOICING_PAYMENT_REMINDERS_SETTINGS;
    static type: ModelType = ModelType.INVOICING_PAYMENT_REMINDERS_SETTINGS;
    prefix: string = 'invoicing:invoicing-payment-reminders-setting';
    static prefix: string = 'invoicing:invoicing-payment-reminders-setting';

    get level(): number {
        return this.attributes.level;
    }

    get dueDays(): number {
        return this.attributes.dueDays;
    }

    get enabled(): boolean {
        return this.attributes.enabled;
    }

    get emailCopy(): boolean {
        return this.attributes.emailCopy;
    }

    get emailSubject(): TranslatedField {
        return this.attributes.emailSubject;
    }

    get emailText(): TranslatedField {
        return this.attributes.emailText;
    }

    get emailTextPreview(): string {
        const text = this.getTranslation('emailText');
        if (!text) {
            return '';
        }

        const doc = new DOMParser().parseFromString(text, 'text/html');
        return Str(doc.body.textContent).limit(200, '...').get();
    }
}
