import PlanningItemCollection from '../collections/PlanningItemCollection';
import PlanningItem from '../models/PlanningItem';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '@/app/base/schemas/BaseSchema';

export default class PlanningItemEndpoint extends BaseEndpoint<PlanningItem, PlanningItemCollection> {
    model: ModelType = ModelType.PLANNING_ITEMS;
    path = 'tenant/planning/planning-items';
    include = 'model,model.users,model.worksite';
}
