import Pdf from '../models/Pdf';
import BaseCollection from '@/app/base/collections/BaseCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import moment from '~~/utils/moment';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import User from '~~/app/auth/models/User';
import PdfStatus from '../enums/PdfStatus';

export default class PdfCollection extends BaseCollection<Pdf> {
    model: ModelType = ModelType.PDFS;
}
