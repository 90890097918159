import GlobalSearchItem from '../models/GlobalSearchItem';
import BaseCollection from '@/app/base/collections/BaseCollection';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class GlobalSearchItemCollection extends BaseCollection<GlobalSearchItem> {
    model: ModelType = ModelType.GLOBAL_SEARCH_ITEMS;

    filterByModelType(modelType: string) {
        return this.filter((GlobalSearchItem: GlobalSearchItem) => GlobalSearchItem.modelType === modelType);
    }
}
