import WorksitePhaseCollection from '../collections/WorksitePhaseCollection';
import { WorksiteMetaProfitability } from '../schemas/WorksiteSchema';
import WorksiteProgressBillingCollection from '../collections/WorksiteProgressBillingCollection';
import InvoiceCollection from '../../invoicing/collections/InvoiceCollection';
import Invoice from '../../invoicing/models/Invoice';
import TaskCollection from '../../task/collections/TaskCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import moment from '~~/utils/moment';
import User from '~~/app/auth/models/User';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import UserCollection from '~/app/auth/collections/UserCollection';
import AddressImage from '~~/classes/AddressImage';
import Contact from '~~/app/contact/models/Contact';
import ModelEventCollection from '@/app/common/collections/ModelEventCollection';
import ModelFileCollection from '@/app/common/collections/ModelFileCollection';

export default class Worksite extends BaseModel {
    type: ModelType = ModelType.WORKSITES;
    static type: ModelType = ModelType.WORKSITES;
    prefix = 'worksite:worksite';
    static prefix = 'worksite:worksite';

    get relationshipsMap(): Record<string, ModelType> {
        return {
            modelEvents: ModelType.MODEL_EVENTS,
            modelFiles: ModelType.MODEL_FILES,
            invoices: ModelType.INVOICES,
            tasks: ModelType.TASKS,
        };
    }

    get link(): string {
        return `/worksite/worksite/${this.getId()}`;
    }

    get progressBillings(): WorksiteProgressBillingCollection | null {
        return this.relationships?.worksiteProgressBillings?.data || null;
    }

    get modelEvents(): ModelEventCollection | null {
        return this.relationships?.modelEvents?.data || null;
    }

    get contact(): Contact | null {
        return this.relationships?.contact?.data || null;
    }

    get users(): UserCollection | null {
        return this.relationships?.users?.data || null;
    }

    get invoices(): InvoiceCollection | null {
        return this.relationships?.invoices?.data || null;
    }

    getQuote(invoices?: InvoiceCollection): InvoiceCollection | Invoice | undefined {
        if (!invoices) return;
        const quotes = invoices.filter((invoice: Invoice) => invoice.documentType === 'quote');
        return quotes || undefined;
    }

    get managers(): UserCollection | null {
        return this.relationships?.managers?.data || null;
    }

    get worksitePhases(): WorksitePhaseCollection | null {
        return this.relationships?.worksitePhases?.data || null;
    }

    get tasks(): TaskCollection | null {
        return this.relationships?.tasks?.data || null;
    }

    get modelFiles(): ModelFileCollection | null {
        return this.relationships?.modelFiles?.data || null;
    }

    hasUser(user: User): boolean {
        if (!this.users) {
            return false;
        }

        return this.users.includes<User>(user);
    }

    get title(): string {
        return this.attributes?.title;
    }

    set title(value: string) {
        this.attributes.title = value;
    }

    get color(): string {
        return this.attributes?.color ?? '#FF0000';
    }

    set color(value: string) {
        this.attributes.color = value;
    }

    get notes(): string | null {
        return this.attributes?.notes;
    }

    set notes(value: string | null) {
        this.attributes.notes = value;
    }

    get address(): Address | null {
        return this.attributes?.address;
    }

    set address(value: Address | null) {
        this.attributes.address = value;
    }

    get addressImage(): string | null {
        return this.address ? AddressImage.make(this.address) : null;
    }

    get startsAt(): moment.Moment | null {
        return this.attributes?.startsAt ? moment(this.attributes.startsAt) : null;
    }

    set startsAt(value: moment.Moment | null) {
        this.attributes.startsAt = value ? value.toDateTimeString() : null;
    }

    get endsAt(): moment.Moment | null {
        return this.attributes?.endsAt ? moment(this.attributes.endsAt) : null;
    }

    set endsAt(value: moment.Moment | null) {
        this.attributes.endsAt = value ? value.toDateTimeString() : null;
    }

    get archivedAt(): moment.Moment | null {
        return this.attributes?.archivedAt ? moment(this.attributes.archivedAt) : null;
    }

    set archivedAt(value: moment.Moment | null) {
        this.attributes.archivedAt = value ? value.toDateTimeString() : null;
    }

    get profitability(): WorksiteMetaProfitability | null {
        return this.meta?.profitability ?? null;
    }
}
