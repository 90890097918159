import WorksitePhase from '../models/WorksitePhase';
import BaseCollection from '@/app/base/collections/BaseCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import moment from '~~/utils/moment';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class WorksitePhaseCollection extends BaseCollection<WorksitePhase> {
    model: ModelType = ModelType.WORKSITE_PHASES;

    defaultItemSortCallback = (a: WorksitePhase, b: WorksitePhase) => {
        return a.startsAt?.isBefore(b.startsAt) ? -1 : 1;
    };

    timelineStartsAt(defaultValue: moment.Moment | null): moment.Moment | null {
        return this.items.length ? this.items[0].startsAt : defaultValue ?? moment();
    }

    timelineEndsAt(defaultValue: moment.Moment | null): moment.Moment | null {
        if (!this.items.length) return defaultValue ?? moment().add(14, 'days');
        return [...this.items].sort((a: WorksitePhase, b: WorksitePhase) => {
            return a.endsAt?.isAfter(b.endsAt) ? -1 : 1;
        })[0].endsAt;
    }

    whereDate(date: moment.Moment): WorksitePhase[] {
        return this.filter((worksitePhase: WorksitePhase) => {
            return date.isBetween(worksitePhase.startsAt, worksitePhase.endsAt, 'day', '[]');
        });
    }
}
