import Invoice from '~~/app/invoicing/models/Invoice';
import { RouteLocationNormalized } from 'vue-router';
import Worksite from '../app/worksite/models/Worksite';

export default class ProgressBillingRoute {
    protected _worksite: string;

    constructor(worksite: string) {
        this._worksite = worksite;
    }

    get worksite() {
        return this._worksite;
    }

    set worksite(value: string) {
        this.worksite = value;
    }

    base(): string {
        return `/worksite/worksite/${this.worksite}/progress-billings`;
    }

    index(): string {
        return this.base();
    }

    create(): string {
        return `${this.base()}/create`;
    }

    edit(id: string): string {
        return `${this.base()}/${id}`;
    }
}
