import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { Meta } from 'zhead';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';
import Worksite from '~~/app/worksite/models/Worksite';
import VatRateCollection from '../collections/VatRateCollection';
import VatRate from '../models/VatRate';

export default class VatRateEndpoint extends BaseEndpoint<VatRate, VatRateCollection> {
    model: ModelType = ModelType.VAT_RATES;
    path: string = 'tenant/invoicing/vat-rates';
}
