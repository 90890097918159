import WorksiteProgressBilling from '../models/WorksiteProgressBilling';
import WorksiteProgressBillingCollection from '../collections/WorksiteProgressBillingCollection';
import { JsonRequestPayload, ModelEndpointResponse } from '../../base/endpoints/types';
import PreviousProgressBillingForm from '../../../composables/useWorksiteProgressBillingForm';
import { progressBillingValidationForm } from '../../../components/worksite/worksite-progress-billing/ValidationModal.vue';
import Pdf from '../../pdf/models/Pdf';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';

export default class WorksiteProgressBillingEndpoint extends BaseEndpoint<WorksiteProgressBilling, WorksiteProgressBillingCollection> {
    model: ModelType = ModelType.WORKSITE_PROGRESS_BILLINGS;
    path = 'tenant/worksite/worksite-progress-billings';
    include = 'createdBy,quote,invoice,worksite';

    async form(worksiteUuid: string, quoteUuid: string, billingProgressid?: string): Promise<PreviousProgressBillingForm> {
        const payload: JsonRequestPayload = {
            method: 'POST',
            data: {
                data: {
                    id: billingProgressid,
                    type: 'worksite-progress-billing-form',
                    attributes: {
                        worksiteId: worksiteUuid,
                        quoteId: quoteUuid,
                    },
                },
            },
            path: '-actions/form',
        };
        const response = await this.requestRaw(payload);
        const json = await response.json();
        return json;
    }

    async requestValidation(billingProgressUuid: string, data: progressBillingValidationForm): Promise<ModelEndpointResponse<WorksiteProgressBilling>> {
        return await this.customAction(billingProgressUuid as string, 'request-validation', 'POST', {
            data: {
                type: 'worksite-progress-billings',
                id: billingProgressUuid,
                attributes: {
                    sendTo: data.sendTo,
                    subject: data.subject,
                    title: data.title,
                    text: data.text,
                },
            },
        });
    }

    async transformToInvoice(billingProgressUuid: string, data: any): Promise<ModelEndpointResponse<WorksiteProgressBilling>> {
        return await this.customAction(billingProgressUuid as string, 'transform/invoice', 'POST', {
            data: {
                type: 'worksite-progress-billings',
                id: billingProgressUuid,
                attributes: {
                    invoiceTitle: data.invoiceTitle,
                    invoiceDescription: data.invoiceDescription,
                    invoiceLineTitle: data.invoiceLineTitle,
                },
            },
        });
    }

    async validated(billingProgressUuid?: string): Promise<ModelEndpointResponse<WorksiteProgressBilling>> {
        return await this.customAction(billingProgressUuid as string, 'validated', 'POST', {
            data: {
                type: 'worksite-progress-billings',
                id: billingProgressUuid,
            },
        });
    }

    // async pdf(billingProgressUuid?: string): Promise<ModelEndpointResponse<Pdf>> {
    //     return await this.customAction(
    //         billingProgressUuid as string,
    //         'pdf',
    //         'POST',
    //         {
    //             data: {
    //                 type: 'worksite-progress-billings',
    //                 id: billingProgressUuid,
    //             }
    //         }
    //     )
    // }

    async pdf(uuid: string): Promise<ModelEndpointResponse<Pdf>> {
        const payload: JsonRequestPayload = {
            method: 'POST',
            path: `${uuid}/-actions/pdf`,
        };

        const pdf = await this.setModel(ModelType.PDFS).request<ModelEndpointResponse<Pdf>>(payload);
        this.setModel(ModelType.WORKSITE_PROGRESS_BILLINGS);
        return pdf;
    }
}
