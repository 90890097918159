import TimeActivityPauseCollection from '../collections/TimeActivityPauseCollection';
import TimeActivityGeolocationCollection from '../collections/TimeActivityGeolocationCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import TimeActivityLiveTimer from '~~/app/planning/classes/TimeActivityLiveTimer';
import TimeActivityCosts from '~~/app/planning/classes/TimeActivityCosts';
import moment from '~~/utils/moment';
import User from '~~/app/auth/models/User';
import Worksite from '~~/app/worksite/models/Worksite';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import WorksitePhase from '~~/app/worksite/models/WorksitePhase';
import Task from '~~/app/task/models/Task';
import Invoice from '~~/app/invoicing/models/Invoice';

export default class TimeActivity extends BaseModel {
    type: ModelType = ModelType.TIME_ACTIVITIES;
    static type: ModelType = ModelType.TIME_ACTIVITIES;
    prefix = 'planning:time-activity';
    static prefix = 'planning:time-activity';

    get user(): User | null {
        return this.relationships?.user?.data || null;
    }

    get worksite(): Worksite | null {
        return this.relationships?.worksite?.data || null;
    }

    get worksitePhase(): WorksitePhase | null {
        return this.relationships?.worksitePhase?.data || null;
    }

    get task(): Task | null {
        return this.relationships?.task?.data || null;
    }

    get timeActivityPauses(): TimeActivityPauseCollection | null {
        return this.relationships?.timeActivityPauses?.data || null;
    }

    get timeActivityGeolocations(): TimeActivityGeolocationCollection | null {
        return this.relationships?.timeActivityGeolocations?.data || null;
    }

    get invoice(): Invoice | null {
        return this.relationships?.invoice?.data || null;
    }

    get title(): string {
        return this.attributes?.title;
    }

    set title(value: string) {
        this.attributes.title = value;
    }

    get startsAt(): moment.Moment | null {
        return this.attributes?.startsAt ? moment(this.attributes.startsAt) : null;
    }

    set startsAt(value: moment.Moment | null) {
        this.attributes.startsAt = value ? value.toDateTimeString() : null;
    }

    get endsAt(): moment.Moment | null {
        return this.attributes?.endsAt ? moment(this.attributes.endsAt) : null;
    }

    set endsAt(value: moment.Moment | null) {
        this.attributes.endsAt = value ? value.toDateTimeString() : null;
    }

    get endsAtOrNow(): moment.Moment {
        return this.endsAt || moment();
    }

    get invoicedAt(): moment.Moment | null {
        return this.attributes?.invoicedAt ? moment(this.attributes.invoicedAt) : null;
    }

    set invoicedAt(value: moment.Moment | null) {
        this.attributes.invoicedAt = value ? value.toDateTimeString() : null;
    }

    get liveTimer(): TimeActivityLiveTimer {
        return new TimeActivityLiveTimer(this);
    }

    get costs(): TimeActivityCosts {
        return new TimeActivityCosts(this);
    }

    get status(): string | null {
        return this.meta?.status;
    }

    get isInvoiced(): boolean {
        return this.attributes?.isInvoiced || false;
    }

    isCompleted(): boolean {
        return this.endsAt !== null;
    }

    isRunning(): boolean {
        return !this.isCompleted();
    }

    isInProgress(): boolean {
        return this.status === 'in_progress';
    }

    isPaused(): boolean {
        return this.status === 'paused';
    }

    isntPaused(): boolean {
        return !this.isPaused();
    }
}
