import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

interface TermsOfServiceAcceptationMeta {
    user: TermsOfServiceAcceptationUserMeta | null;
    createdAt: string | null;
}

interface TermsOfServiceAcceptationUserMeta {
    email: string;
    role: string;
}

export default class TermsOfService extends BaseModel {
    type: ModelType = ModelType.TERMS_OF_SERVICES;
    static type: ModelType = ModelType.TERMS_OF_SERVICES;
    prefix: string = 'common:terms-of-services';
    static prefix: string = 'common:terms-of-services';

    get version(): string {
        return this.attributes.version;
    }

    get billingCountry(): string {
        return this.attributes.billingCountry;
    }

    get locale(): string {
        return this.attributes.locale;
    }

    get html(): string {
        return this.attributes.html;
    }

    get pdfUrl(): string {
        return this.attributes.pdfUrl;
    }

    get acceptation(): TermsOfServiceAcceptationMeta | null {
        return this.meta?.acceptation;
    }
}
