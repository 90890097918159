import TimeActivityCollection from '../collections/TimeActivityCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import moment from '~~/utils/moment';
import User from '~~/app/auth/models/User';
import Worksite from '~~/app/worksite/models/Worksite';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import UserCollection from '~/app/auth/collections/UserCollection';
import WorksitePhase from '~~/app/worksite/models/WorksitePhase';
import Lead from '~~/app/crm/models/Lead';
import { CalendarEventType } from '../enums/CalendarEventType';

export default class CalendarEvent extends BaseModel {
    type: ModelType = ModelType.CALENDAR_EVENTS;
    static type: ModelType = ModelType.CALENDAR_EVENTS;
    prefix = 'planning:calendar-event';
    static prefix = 'planning:calendar-event';

    get users(): UserCollection | null {
        return this.relationships?.users?.data || null;
    }

    hasUser(user: User): boolean {
        if (!this.users) {
            return false;
        }

        return this.users.includes<User>(user);
    }

    get lead(): Lead | null {
        return this.relationships?.lead?.data || null;
    }

    get worksite(): Worksite | null {
        return this.relationships?.worksite?.data || null;
    }

    get worksitePhase(): WorksitePhase | null {
        return this.relationships?.worksitePhase?.data || null;
    }

    get contact(): Contact | null {
        if (this.relationships?.worksite?.data && this.relationships?.worksite?.data?.relationships?.contact?.data) {
            return this.relationships?.worksite?.data?.relationships?.contact?.data;
        }

        return this.relationships?.contact?.data || null;
    }

    get timeActivities(): TimeActivityCollection | null {
        return this.relationships?.timeActivities?.data || null;
    }

    get displayTitle(): string {
        if (this.worksite) {
            return this.worksite.title;
        }

        return this.title;
    }

    get title(): string {
        return this.attributes?.title;
    }

    set title(value: string) {
        this.attributes.title = value;
    }

    get notes(): string {
        return this.attributes?.notes;
    }

    set notes(value: string) {
        this.attributes.notes = value;
    }

    get address(): Address | null {
        return this.attributes?.address || null;
    }

    set address(value: Address | null) {
        this.attributes.address = value;
    }

    get startsAt(): moment.Moment | null {
        return this.attributes?.startsAt ? moment(this.attributes.startsAt) : null;
    }

    set startsAt(value: moment.Moment | null) {
        this.attributes.startsAt = value ? value.toDateTimeString() : null;
    }

    get endsAt(): moment.Moment | null {
        return this.attributes?.endsAt ? moment(this.attributes.endsAt) : null;
    }

    set endsAt(value: moment.Moment | null) {
        this.attributes.endsAt = value ? value.toDateTimeString() : null;
    }

    get status(): string | null {
        return this.meta?.status;
    }

    get eventType(): CalendarEventType {
        return new CalendarEventType(this.attributes.eventType || 'worksite');
    }

    get timetrackingAllowed(): boolean {
        return this.attributes.timetrackingAllowed;
    }

    get accountedForWeeklyHours(): boolean {
        return this.attributes.accountedForWeeklyHours;
    }

    get timetrackingStatus(): string | null {
        return this.meta.timetrackingStatus;
    }

    toFullCalendarEvent(): any {
        return {
            id: this.getId(),
            title: this.displayTitle,
            start: this.startsAt?.toISOString(),
            end: this.endsAt?.toISOString(),
            resourceId: this.users?.map,
        };
    }
}
