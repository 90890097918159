import BaseCollection from '@/app/base/collections/BaseCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import moment from '~~/utils/moment';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import Task from '../models/Task';

export default class TaskCollection extends BaseCollection<Task> {
    model: ModelType = ModelType.TASKS;

    whereDate(date: moment.Moment): Task[] {
        return this.filter((task: Task) => {
            return task.dueDate?.isSame(date, 'day');
        });
    }
}
