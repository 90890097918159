import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class LoginQrCode extends BaseModel {
    type: ModelType = ModelType.LOGIN_QR_CODES;
    static type: ModelType = ModelType.LOGIN_QR_CODES;
    prefix = 'auth:login-qr-code';
    static prefix = 'auth:login-qr-code';

    get qrCode(): string {
        return this.meta.qrCode;
    }
}
