import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { Meta } from 'zhead';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';
import Worksite from '~~/app/worksite/models/Worksite';
import InvoiceAutosaveCollection from '../collections/InvoiceAutosaveCollection';
import InvoiceAutosave from '../models/InvoiceAutosave';

export default class InvoiceAutosaveEndpoint extends BaseEndpoint<InvoiceAutosave, InvoiceAutosaveCollection> {
    model: ModelType = ModelType.INVOICE_AUTOSAVES;
    path: string = 'tenant/invoicing/invoice-autosaves';
}
