import BaseModel from '~~/app/base/models/BaseModel';
import moment from '~~/utils/moment';
import User from '~~/app/auth/models/User';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import UserCollection from '~/app/auth/collections/UserCollection';
import AddressImage from '~~/classes/AddressImage';
import Worksite from '~~/app/worksite/models/Worksite';
import WorksitePhase from '~~/app/worksite/models/WorksitePhase';
import TimeActivityCollection from '~~/app/planning/collections/TimeActivityCollection';
import TimeActivity from '~~/app/planning/models/TimeActivity';
import Contact from '@/app/contact/models/Contact';
import Lead from '@/app/crm/models/Lead';

export default class Task extends BaseModel {
    type: ModelType = ModelType.TASKS;
    static type: ModelType = ModelType.TASKS;
    prefix: string = 'task:task';
    static prefix: string = 'task:task';

    get relationshipsMap(): Record<string, ModelType> {
        return {
            worksite: ModelType.WORKSITES,
            worksitePhase: ModelType.WORKSITE_PHASES,
            users: ModelType.USERS,
            createdBy: ModelType.USERS,
            completedBy: ModelType.USERS,
            timeActivities: ModelType.TIME_ACTIVITIES,
        };
    }

    relationshipsMapped(): void {
        if (this.relationships.timeActivities && this.relationships.timeActivities.data && typeof this.relationships.timeActivities.data.isModelOrCollection === 'function') {
            this.relationships.timeActivities.data.map((timeActivity: TimeActivity) => timeActivity.setRelationship('task', this.cloneWithoutRelationships(ModelType.TASKS)));
            this.relationships.timeActivities.data.setItemShouldBeInCollectionCallback((timeActivity: TimeActivity) => timeActivity.task?.getId() === this.getId());
        }
    }

    get contact(): Contact | null {
        return this.relationships?.contact?.data || null;
    }

    get lead(): Lead | null {
        return this.relationships?.lead?.data || null;
    }

    get worksite(): Worksite | null {
        return this.relationships?.worksite?.data || null;
    }

    get worksitePhase(): WorksitePhase | null {
        return this.relationships?.worksitePhase?.data || null;
    }

    get users(): UserCollection | null {
        return this.relationships?.users?.data || null;
    }

    get createdBy(): User | null {
        return this.relationships?.createdBy?.data || null;
    }

    get completedBy(): User | null {
        return this.relationships?.completedBy?.data || null;
    }

    get timeActivities(): TimeActivityCollection | null {
        return this.relationships?.timeActivities?.data || null;
    }

    get title(): string {
        return this.attributes?.title;
    }

    set title(title: string) {
        this.attributes.title = title;
    }

    get description(): string {
        return this.attributes?.description;
    }

    set description(description: string) {
        this.attributes.description = description;
    }

    get completedAt(): moment.Moment | null {
        return this.attributes?.completedAt ? moment(this.attributes?.completedAt) : null;
    }

    set completedAt(completedAt: moment.Moment | null) {
        this.attributes.completedAt = completedAt;
    }

    get isCompleted(): boolean {
        return this.completedAt !== null;
    }

    get dueDate(): moment.Moment | null {
        return this.attributes?.dueDate ? moment(this.attributes?.dueDate) : null;
    }

    set dueDate(dueDate: moment.Moment | null) {
        this.attributes.dueDate = dueDate;
    }

    get isDue(): boolean {
        return !this.isCompleted && this.dueDate !== null && this.dueDate.isBefore(moment());
    }

    get options(): Record<string, any> | null {
        return this.attributes?.options || null;
    }

    get timeActivitiesTotalDurationRaw(): number | null {
        return this.meta?.timeActivitiesTotalDuration || null;
    }

    get timeActivitiesTotalDuration(): moment.Duration | null {
        return this.timeActivitiesTotalDurationRaw ? moment.duration(this.timeActivitiesTotalDurationRaw, 'seconds') : null;
    }

    get commentsCount(): number {
        return this.meta?.commentsCount || 0; // TODO: Find a way to store/eager load the comments count from Messaging somewhere.
    }
}
