import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type CalendarEventRelationships = {
    users: ToManyRelationship<ModelType.USERS>;
    lead: ToOneRelationship<ModelType.LEADS>;
    worksite: ToOneRelationship<ModelType.WORKSITES>;
    worksitePhase: ToOneRelationship<ModelType.WORKSITE_PHASES>;
    contact: ToOneRelationship<ModelType.CONTACTS>;
};

type CalendarEventAttributes = {
    title: string;
    address?: Address | null;
    startsAt: string;
    endsAt: string;
    eventType: string;
    timetrackingAllowed: boolean;
    accountedForWeeklyHours: boolean;
    notes: string;
};

type CalendarEventMeta = {};

export default class CalendarEventSchema extends BaseSchema<CalendarEventAttributes, CalendarEventMeta, CalendarEventRelationships> {
    protected type: ModelType = ModelType.CALENDAR_EVENTS;
}
