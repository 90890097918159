import { Meta } from 'zhead';
import LeadCollection from '../collections/LeadCollection';
import Lead from '../models/Lead';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';
import Worksite from '~~/app/worksite/models/Worksite';

export default class LeadEndpoint extends BaseEndpoint<Lead, LeadCollection> {
    model: ModelType = ModelType.LEADS;
    path = 'tenant/crm/leads';
    include = 'user,contact,source';

    async transformToWorksite(uuid: string): Promise<ModelEndpointResponse<Worksite>> {
        return await this.customAction(uuid, 'transform/worksite', 'POST');
    }
}
