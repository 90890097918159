import User from '~~/app/auth/models/User';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import Contact from '~~/app/contact/models/Contact';
import CalendarEventCollection from '~~/app/planning/collections/CalendarEventCollection';
import Worksite from '~~/app/worksite/models/Worksite';
import moment from '~~/utils/moment';
import PdfStatus from '../enums/PdfStatus';
import PdfImageCollection from '../collections/PdfImageCollection';

export default class PdfImage extends BaseModel {
    type: ModelType = ModelType.PDF_IMAGES;
    static type: ModelType = ModelType.PDF_IMAGES;
    prefix: string = 'pdf-image';
    static prefix: string = 'pdf-image';

    get url(): string {
        return this.attributes.url;
    }

    get page(): number | null {
        return this.attributes.page;
    }
}
