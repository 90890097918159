import WorksiteClosureReport from '../models/WorksiteClosureReport';
import WorksiteClosureReportCollection from '../collections/WorksiteClosureReportCollection';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import { ModelEndpointResponse } from '@/app/base/endpoints/types';

export default class WorskiteClosureReportEndpoint extends BaseEndpoint<WorksiteClosureReport, WorksiteClosureReportCollection> {
    model: ModelType = ModelType.WORKSITES_CLOSURE_REPORT;
    path = 'tenant/worksite/closure-reports';
    include = 'worksite,invoice,contact';

    async notify(uuid: string, driver: 'email' | 'sms' | 'letter', data: any): Promise<ModelEndpointResponse<WorksiteClosureReport>> {
        return await this.customAction(uuid, `notify/${driver}`, 'POST', {
            data: {
                type: 'closure-reports',
                id: uuid,
                attributes: {
                    sendTo: data.sendTo,
                    subject: data.subject,
                    title: data.title,
                    text: data.text,
                },
            },
        });
    }

    // Get the public document token url for the given model allowing tenant sign the document from the app.
    async getPublicLink(uuid: string): Promise<any> {
        return await this.customAction(uuid, 'sign/document', 'GET');
    }
}
// api / v1 / tenant / worksite / closure - reports / { closure_report } / -actions / notify / email
