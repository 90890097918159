import PlanningItem from '../models/PlanningItem';
import BaseCollection from '@/app/base/collections/BaseCollection';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import CalendarEventCollection from './CalendarEventCollection';
import User from '@/app/auth/models/User';
import TaskCollection from '@/app/task/collections/TaskCollection';
import WorksitePhaseCollection from '@/app/worksite/collections/WorksitePhaseCollection';
import CalendarEvent from '../models/CalendarEvent';
import Task from '@/app/task/models/Task';
import WorksitePhase from '@/app/worksite/models/WorksitePhase';
import EndpointFactory from '@/app/factories/EndpointFactory';
import { CalendarEventType } from '../enums/CalendarEventType';

export default class PlanningItemCollection extends BaseCollection<PlanningItem> {
    onSubCollectionChange: Function | null = null;

    model: ModelType = ModelType.PLANNING_ITEMS;

    findByModel(model: CalendarEvent | Task | WorksitePhase): PlanningItem | null {
        return this.find((planningItem: PlanningItem) => planningItem.model?.getId() === model.getId());
    }

    filterByModelType(modelType: string) {
        return this.filter((planningItem: PlanningItem) => planningItem.modelType === modelType);
    }

    toSubCollection(modelType: string): CalendarEventCollection | TaskCollection | WorksitePhaseCollection {
        const models = this.filterByModelType(modelType)
            .map((planningItem: PlanningItem) => planningItem.model)
            .filter((model: any) => model !== null) as Array<CalendarEvent | Task | WorksitePhase>;

        switch (modelType) {
            case 'calendar-event':
                return this.createSubCollection<CalendarEvent>(CalendarEventCollection, models);

            case 'task':
                return this.createSubCollection<Task>(TaskCollection, models);

            case 'worksite-phase':
                return this.createSubCollection<WorksitePhase>(WorksitePhaseCollection, models);

            default:
                throw new Error('Invalid model type');
        }
    }

    createSubCollection<Model>(collectionClass: any, models: Array<Model>): any {
        var subCollection = collectionClass.makeFromModels(models);

        subCollection.onItemAdded = async (model: Model) => {
            const endpoint = EndpointFactory.make(ModelType.PLANNING_ITEMS);
            const response = await endpoint.index({
                filter: {
                    modelId: model.getId(),
                },
            });
            if (response.data) {
                console.log(response.data);
                useEvent('planning:planning-item:created', response.data);
                this.onSubCollectionChange?.();
            }
        };
        subCollection.onItemUpdated = async (model: Model) => {
            const planningItem = this.find((planningItem: PlanningItem) => planningItem.model?.getId() == model.getId());
            if (planningItem) {
                const endpoint = EndpointFactory.make(ModelType.PLANNING_ITEMS);
                const response = await endpoint.retrieve(planningItem.getId());
                if (response.data) {
                    useEvent('planning:planning-item:updated', response.data);
                    this.onSubCollectionChange?.();
                }
            }
        };
        subCollection.onItemRemoved = (id: string) => {
            const planningItem = this.find((planningItem: PlanningItem) => planningItem.model?.getId() == id);
            if (planningItem) {
                useEvent('planning:planning-item:deleted', planningItem.getId());
                this.onSubCollectionChange?.();
            }
        };

        return subCollection;
    }

    calendarEvents(): CalendarEventCollection {
        return this.toSubCollection('calendar-event') as CalendarEventCollection;
    }

    tasks(): TaskCollection {
        return this.toSubCollection('task') as TaskCollection;
    }

    worksitePhases(): WorksitePhaseCollection {
        return this.toSubCollection('worksite-phase') as WorksitePhaseCollection;
    }

    toFullCalendarEvents(): Array<any> {
        return this.map((event: PlanningItem) => event.toFullCalendarEvent());
    }

    conflictExists(users: User[], startsAt: moment.Moment, endsAt: moment.Moment): boolean {
        return (
            this.filter((planningItem: PlanningItem) => {
                return (
                    planningItem.modelType === 'calendar-event' && planningItem.startsAt.isBetween(startsAt, endsAt, null, '[]') && planningItem.model && planningItem.model.users?.includesAny(users)
                );
            }).length > 0
        );
    }
}
