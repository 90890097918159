import Workfield from '../models/Workfield';
import BaseCollection from '@/app/base/collections/BaseCollection';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class WorkfieldCollection extends BaseCollection<Workfield> {
    model: ModelType = ModelType.WORKFIELDS;

    defaultItemSortCallback = (a: Workfield, b: Workfield) => {
        // Sort by alphabetical order of the title, but always put the workfield with the key "other" at the end
        if (a.key === 'other') {
            return 1;
        }
        if (b.key === 'other') {
            return -1;
        }
        return a.title.localeCompare(b.title);
    };
}
