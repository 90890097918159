import Worksite from '../../worksite/models/Worksite';
import User from '~~/app/auth/models/User';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import Media from '~~/app/common/models/Media';

export default class ModelFile extends BaseModel {
    type: ModelType = ModelType.MODEL_FILES;
    static type: ModelType = ModelType.MODEL_FILES;
    prefix = 'common:model-file';
    static prefix = 'common:model-file';

    get model(): Worksite | Invoice | null {
        return this.relationships?.model?.data || null;
    }

    get user(): User | null {
        return this.relationships?.user?.data || null;
    }

    get title(): string {
        return this.attributes.title;
    }

    get description(): string {
        return this.attributes.description;
    }

    get mediaId(): string {
        return this.attributes.mediaId;
    }

    get media(): Media | null {
        return this.meta.media ? Media.make(this.meta.media) : null;
    }

    get mediaSize(): number | null {
        return this.attributes.mediaSize;
    }

    getMediaReadableSize(decimals = 2): string {
        if (this.mediaSize === 0 || this.mediaSize === null) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(this.mediaSize) / Math.log(k));

        return parseFloat((this.mediaSize / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    get mediaType(): string | null {
        return this.attributes.mediaType;
    }

    get displayMediaType(): string | null {
        if (!this.mediaType) {
            return null;
        }

        switch (this.mediaType) {
            case 'application/pdf':
                return 'pdf';

            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'word';

            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return 'excel';

            case 'text/csv':
                return 'csv';

            case 'text/plain':
                return 'txt';

            default:
                return this.mediaType.substring(this.mediaType.indexOf('/') + 1);
        }
    }

    getDownloadUrl(): string | null {
        return this.media?.url || null;
    }

    get url(): string | null {
        return this.media?.url || null;
    }

    get thumbnail(): string | null {
        if (!this.media) {
            return null;
        }

        return this.media.collection == 'images' ? this.media.url : null;
    }

    get faIconClass(): string {
        switch (this.mediaType) {
            case 'application/pdf':
                return 'fa-solid fa-file-pdf text-red-600';

            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'fas fa-file-word text-blue-600';

            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return 'fas fa-file-excel text-green-600';

            case 'text/csv':
                return 'fas fa-file-csv text-green-600';

            case 'text/plain':
                return 'fas fa-file-alt text-gray-500';

            default:
                return 'fas fa-file text-gray-500';
        }
    }

    isPdf(): boolean {
        return this.mediaType === 'application/pdf';
    }
}
