import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class InvoiceDiscount extends BaseModel {
    type: ModelType = ModelType.INVOICE_DISCOUNTS;
    static type: ModelType = ModelType.INVOICE_DISCOUNTS;
    prefix: string = 'invoicing:invoice-discount';
    static prefix: string = 'invoicing:invoice-discount';

    get title(): string {
        return this.attributes.title;
    }

    get displayTitle(): string {
        if (this.discountType !== 'custom') {
            const { t } = useTranslation();
            return t(`invoicing.invoice_discount_type.${this.discountType}`);
        }
        return this.title;
    }

    get value(): number {
        return this.attributes.value;
    }

    get valueType(): string {
        return this.attributes.valueType;
    }

    get discountType(): string {
        return this.attributes.discountType;
    }

    get appliedBeforeTax(): boolean {
        return this.attributes.appliedBeforeTax;
    }
}
