import { JsonRequestPayload } from './types';
const runtimeConfig = useRuntimeConfig();

export default abstract class RawEndpoint {
    /**
     * The path to be used for the request, this should be overridden by the child class.
     */
    abstract path: string;

    /**
     * The AbortController used to possibly cancel the request.
     */
    abortController: AbortController | null = null;

    /**
     * The custom auth token to be used for the request.
     */
    customAuthToken: string | null = null;

    /**
     * The custom headers to be used on top of the defaults for the request.
     */
    customHeaders: Record<string, string> = {};

    /**
     * Whether or not the request should be made with the precognition header.
     * This header is used to return potential validation errors without processing the actual content.
     */
    preconditionOnly = false;

    withCredentials(): boolean {
        return true;
    }

    withCustomAuthToken(token: string) {
        this.customAuthToken = token;
        return this;
    }

    withoutCustomAuthToken() {
        this.customAuthToken = null;
        return this;
    }

    withAbortController(controller: AbortController) {
        this.abortController = controller;
        return this;
    }

    withoutAbortController() {
        this.abortController = null;
        return this;
    }

    withCustomHeaders(headers: Record<string, string>) {
        this.customHeaders = headers;
        return this;
    }

    withoutCustomHeaders() {
        this.customHeaders = {};
        return this;
    }

    usingPrecognition() {
        this.preconditionOnly = true;
        return this;
    }

    withoutPrecognition() {
        this.preconditionOnly = false;
        return this;
    }

    /**
     * The final URL to be used for the request.
     */
    getUrl(path: string): string {
        return `${runtimeConfig.public.worksiteApiUrl}/api/v1/${this.path}/${path}`;
    }

    getRequestHeaders(): Headers {
        const { usableToken, authed, tenant } = useAuthStore();
        const translation = useTranslation();
        const { isImpersonating } = useAuthStore();
        const headers: Headers = new Headers();
        headers.set('Accept', 'application/vnd.api+json');
        headers.set('Content', 'application/vnd.api+json');
        headers.set('Content-Type', 'application/vnd.api+json');
        if (isImpersonating) headers.set('X-Impersonating-Token', localStorage.getItem('token'));
        headers.set('Accept-Language', translation?.getCurrentLocale() || authed?.locale || useGetDefaultLocale());
        headers.set('Content-Language', translation?.getCurrentLocale() || authed?.locale || useGetDefaultLocale());
        headers.set('X-Requested-With', 'XMLHttpRequest');
        headers.set('X-Tracking-Group-Id', window.tracking_group_id || '');
        headers.set('X-App-Url', window.location.href);

        if (this.withCredentials()) {
            if (this.customAuthToken) {
                headers.set('Authorization', this.customAuthToken);
            } else if (usableToken) {
                headers.set('Authorization', usableToken);
            }
            headers.set('X-Tenant-ID', tenant?.getId());
        }

        if (this.preconditionOnly) {
            headers.set('Precognition', 'true');
        }

        if (Object.keys(this.customHeaders).length > 0) {
            for (const [key, value] of Object.entries(this.customHeaders)) {
                headers.set(key, value);
            }
        }

        return headers;
    }

    getParametersForFormData(parameters: RequestInit, data: FormData): RequestInit {
        for (const pair of data.entries()) {
            if (pair[1] == 'null') {
                data.set(pair[0], '');
            }
        }

        if (parameters.method == 'PUT') {
            parameters.method = 'POST';
            data.append('_method', 'PUT');
        }

        parameters.body = data;
        // parameters.headers!.set('Accept', 'multipart/form-data');
        parameters.headers!.delete('Content-Type'); // Let the browser set it with the correct boundary

        return parameters;
    }

    /**
     * Makes a fecth request to the API.
     */
    requestRaw(payload: JsonRequestPayload): Promise<Response> {
        let parameters: RequestInit = {
            method: payload.method,
            headers: this.getRequestHeaders(),
        };

        if (payload.method !== 'GET' && payload.data instanceof FormData === false && payload.data && Object.keys(payload.data).length > 0) {
            parameters.body = JSON.stringify(payload.data);
        }

        if (payload.data instanceof FormData) {
            console.log('Is form data');
            parameters = this.getParametersForFormData(parameters, payload.data);
        }

        if (this.abortController) {
            parameters.signal = this.abortController.signal;
        }

        return fetch(this.getUrl(payload.path), parameters);
    }
}
