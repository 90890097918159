import Worksite from '../models/Worksite';
import BaseCollection from '@/app/base/collections/BaseCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import moment from '~~/utils/moment';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import WorksiteProgressBilling from '../models/WorksiteProgressBilling';

export default class WorksiteProgressBillingLinesCollection extends BaseCollection<WorksiteProgressBilling> {
    model: ModelType = ModelType.WORKSITE_PROGRESS_BILLING_LINES;
}
