import { Meta } from 'zhead';
import PdfCollection from '../collections/PdfCollection';
import Pdf from '../models/Pdf';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';
import Worksite from '~~/app/worksite/models/Worksite';
import Invoice from 'app/invoicing/models/Invoice';

export default class PdfEndpoint extends BaseEndpoint<Pdf, PdfCollection> {
    model: ModelType = ModelType.PDFS;
    path = 'tenant/pdfs';
    include = 'pdfImages';

    async upload(file: File): Promise<ModelEndpointResponse<Pdf>> {
        const data = new FormData();
        data.append('data[type]', 'pdfs');
        data.append('data[attributes][file]', file);

        return await this.customAction(null, 'upload', 'POST', data);
    }

    async signDocument(invoice: Invoice): Promise<ModelEndpointResponse<Pdf>> {
        const data = new FormData();
        data.append('data[type]', 'sign-document');
        data.append('data[attributes][modelId]', invoice.getId());
        data.append('data[attributes][modelType]', 'invoice');

        return await this.customAction(null, 'sign-document', 'POST', data);
    }

    async attachSignedDocument(signedDocumentId: string): Promise<ModelEndpointResponse<Pdf>> {
        const data = new FormData();
        data.append('data[type]', 'signed-document');
        data.append('data[attributes][signedDocumentId]', signedDocumentId);

        return await this.customAction(null, 'attach-signed-document', 'POST', data);
    }
}
