import Invoice from '../../invoicing/models/Invoice';
import WorksiteClosureReportStatus from '../enums/WorksiteClosureReportStatus';
import Worksite from './Worksite';
import Pdf from '@/app/pdf/models/Pdf';
import ModelEventCollection from '@/app/common/collections/ModelEventCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import Contact from '~~/app/contact/models/Contact';

export default class WorksiteClosureReport extends BaseModel {
    type: ModelType = ModelType.WORKSITES_CLOSURE_REPORT;
    static type: ModelType = ModelType.WORKSITES_CLOSURE_REPORT;
    prefix = 'worksite:worksite:closure-reports';
    static prefix = 'worksite:worksite:closure-reports';

    get relationshipsMap(): Record<string, ModelType> {
        return {
            contact: ModelType.CONTACTS,
            worksite: ModelType.WORKSITES,
            invoice: ModelType.INVOICES,
            modelEvents: ModelType.MODEL_EVENTS,
        };
    }

    get quote(): Invoice | null {
        return this.relationships?.invoice?.data || null;
    }

    get pdf(): Pdf | null {
        return this.relationships?.pdf?.data || null;
    }

    get worksite(): Worksite | null {
        return this.relationships?.worksite?.data || null;
    }

    get contact(): Contact {
        return this.relationships?.contact?.data || null;
    }

    get modelEvents(): ModelEventCollection | null {
        return this.relationships?.modelEvents?.data || null;
    }

    get address(): Address | null {
        return this.attributes?.address;
    }

    set address(value: Address | null) {
        this.attributes.address = value;
    }

    get reserves(): { title: string; text: string }[] | null {
        return this.attributes?.reserves;
    }

    get title(): string | null {
        return this.attributes?.title;
    }

    hasReserves(): boolean {
        return !!this.reserves && this.reserves.length > 0;
    }

    get internalNumber(): string | null {
        return this.attributes?.internalNumber;
    }

    get status(): WorksiteClosureReportStatus | null {
        return this.attributes?.status;
    }

    set status(value: WorksiteClosureReportStatus | null) {
        this.attributes.status = value;
    }

    get reservesRemovedAt() {
        return this.attributes?.reservesRemovedAt ? moment(this.attributes.reservesRemovedAt) : null;
    }
}
