import ProductCollection from '../collections/ProductCollection';
import Product from '../models/Product';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class ProductEndpoint extends BaseEndpoint<Product, ProductCollection> {
    model: ModelType = ModelType.PRODUCTS;
    path: string = 'tenant/inventory/products';
    include: string = 'productUnit,productCategory,productImage,defaultVatRate,composedWorkLines,composedWorkLines.productUnit';
}
