import InvoiceTemplateSectionCollection from '../collections/InvoiceTemplateSectionCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class InvoiceTemplate extends BaseModel {
    type: ModelType = ModelType.INVOICE_TEMPLATES;
    static type: ModelType = ModelType.INVOICE_TEMPLATES;
    prefix: string = 'invoicing:invoice-templates';
    static prefix: string = 'invoicing:invoice-templates';

    get invoiceTemplateSections(): InvoiceTemplateSectionCollection | null {
        return this.relationships?.invoiceTemplateSections?.data || null;
    }

    get title(): string {
        return this.attributes.title;
    }

    get default(): boolean {
        return this.attributes.default;
    }

    get locale(): string {
        return this.attributes.locale;
    }

    get documentType(): string {
        return this.attributes.documentType;
    }

    get logoStyle(): object {
        return this.attributes.logoStyle || this.meta.logoStyle;
    }

    get tableColumns(): object {
        return this.attributes.tableColumns;
    }

    get tableStyle(): object {
        return this.attributes.tableStyle;
    }

    get headerImageRepeat(): boolean {
        return this.attributes.headerImageRepeat ?? false;
    }

    get headerImageUrl(): string | null {
        return this.attributes.headerImageUrl;
    }

    get headerImageHeight(): number | null {
        return this.attributes.headerImageHeight;
    }

    get headerImageWidth(): number | null {
        return this.attributes.headerImageWidth;
    }

    get footerImageUrl(): string | null {
        return this.attributes.footerImageUrl;
    }

    get footerImageHeight(): number | null {
        return this.attributes.footerImageHeight;
    }

    get footerImageWidth(): number | null {
        return this.attributes.footerImageWidth;
    }

    get footerText(): string | null {
        return this.attributes.footerText;
    }
}
