import ProductUnit from '../models/ProductUnit';
import BaseCollection from '@/app/base/collections/BaseCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import moment from '~~/utils/moment';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import User from '~~/app/auth/models/User';
import ProductUnitStatus from '../enums/ProductUnitStatus';

export default class ProductUnitCollection extends BaseCollection<ProductUnit> {
    model: ModelType = ModelType.PRODUCT_UNITS;
}
