import TenantCollection from '../collections/TenantCollection';
import Tenant from '../models/Tenant';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { JsonRequestPayload, ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import { Mode } from '~/app/enums/tenant/Mode';

export default class TenantEndpoint extends BaseEndpoint<Tenant, TenantCollection> {
    model: ModelType = ModelType.TENANTS;
    path = 'tenants';

    async default(): Promise<ModelEndpointResponse<Tenant>> {
        const payload: JsonRequestPayload = {
            method: 'GET',
            path: '-actions/default?include=tenantUsers,workfield',
        };

        return await this.request<ModelEndpointResponse<Tenant>>(payload);
    }

    async setDefault(uuid: string): Promise<ModelEndpointResponse<Tenant>> {
        const payload: JsonRequestPayload = {
            method: 'PATCH',
            path: `${uuid}/-actions/default`,
        };

        return await this.request<ModelEndpointResponse<Tenant>>(payload);
    }

    async updateLogo(uuid: string, logo: string): Promise<ModelEndpointResponse<Tenant>> {
        const payload: JsonRequestPayload = {
            method: 'PATCH',
            path: `${uuid}/-actions/logo`,
            data: {
                data: {
                    type: 'tenants',
                    id: uuid,
                    attributes: {
                        logo,
                    },
                },
            },
        };

        return await this.request<ModelEndpointResponse<Tenant>>(payload);
    }

    async mode(uuid: string, mode: Mode = 'live'): Promise<ModelEndpointResponse<Tenant>> {
        const payload: JsonRequestPayload = {
            method: 'PATCH',
            path: `${uuid}/-actions/mode?include=tenantUsers`,
            data: {
                data: {
                    type: 'tenants',
                    id: uuid,
                    attributes: {
                        mode,
                    },
                },
            },
        };

        return await this.request<ModelEndpointResponse<Tenant>>(payload);
    }

    async activateAccount(uuid: string): Promise<ModelEndpointResponse<Tenant>> {
        const payload: JsonRequestPayload = {
            method: 'POST',
            path: `${uuid}/-actions/activate/account`,
            data: {
                data: {
                    type: 'tenants',
                    id: uuid,
                },
            },
        };

        return await this.request<ModelEndpointResponse<Tenant>>(payload);
    }
}
