import ComposedWorkLineCollection from 'app/inventory/collections/ComposedWorkLineCollection';
import VatRate from './VatRate';
import Invoice from './Invoice';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import Product from '~~/app/inventory/models/Product';
import ProductUnit from '~~/app/inventory/models/ProductUnit';
import TimeActivity from '~~/app/planning/models/TimeActivity';
import ProductImage from '@/app/inventory/models/ProductImage';

export default class InvoiceLine extends BaseModel {
    type: ModelType = ModelType.INVOICE_LINES;
    static type: ModelType = ModelType.INVOICE_LINES;
    prefix: string = 'invoicing:invoice-line';
    static prefix: string = 'invoicing:invoice-line';

    get relationshipsMap(): Record<string, ModelType> {
        return {
            composedWorkLines: ModelType.COMPOSED_WORK_LINES,
            originalInvoice: ModelType.INVOICES,
        };
    }

    get timeActivity(): TimeActivity | null {
        return this.relationships?.timeActivity?.data || null;
    }

    get originalInvoice(): Invoice | null {
        return this.relationships?.originalInvoice?.data || null;
    }

    get composedWorkLines(): ComposedWorkLineCollection | null {
        return this.relationships?.composedWorkLines?.data || null;
    }

    get title(): string | null {
        return this.attributes.title;
    }

    set title(value: string | null) {
        this.attributes.title = value;
    }

    get description(): string | null {
        return this.attributes.description;
    }

    set description(value: string | null) {
        this.attributes.description = value;
    }

    get purchasePrice(): number | null {
        return this.attributes.purchasePrice;
    }

    set purchasePrice(value: number | null) {
        this.attributes.purchasePrice = value;
    }

    get price(): number | null {
        return this.attributes.price;
    }

    set price(value: number | null) {
        this.attributes.price = value;
    }

    get subTotal(): number | null {
        return this.attributes.subtotal;
    }

    get quantity(): number | null {
        return this.attributes.quantity;
    }

    get discount(): number | null {
        return this.attributes.discount;
    }

    get lineType(): string {
        return this.attributes.lineType || 'line';
    }

    get vatRate(): VatRate | null {
        return this.relationships?.vatRate?.data || null;
    }

    get product(): Product | null {
        return this.relationships?.product?.data || null;
    }

    get productUnit(): ProductUnit | null {
        return this.relationships?.productUnit?.data || null;
    }

    get productImage(): ProductImage | null {
        return this.relationships?.productImage?.data || null;
    }

    getForm() {
        return this.attributes.form;
    }
}
