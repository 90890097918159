import InvoiceTemplateSection from '../models/InvoiceTemplateSection';
import BaseCollection from '@/app/base/collections/BaseCollection';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class InvoiceTemplateSectionCollection extends BaseCollection<InvoiceTemplateSection> {
    model: ModelType = ModelType.INVOICE_TEMPLATE_SECTIONS;

    defaultItemSortCallback = (a: InvoiceTemplateSection, b: InvoiceTemplateSection) => {
        if (a.beforeTable && !b.beforeTable) {
            return -1;
        }
        if (!a.beforeTable && b.beforeTable) {
            return 1;
        }
        return a.order - b.order;
    };
}
