import InvoiceLineCollection from '../collections/InvoiceLineCollection';
import ExpenseStatus from '../enums/ExpenseStatus';
import User from '~~/app/auth/models/User';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import Contact from '~~/app/contact/models/Contact';
import CalendarEventCollection from '~~/app/planning/collections/CalendarEventCollection';
import Worksite from '~~/app/worksite/models/Worksite';
import moment from '~~/utils/moment';

export default class InvoiceSection extends BaseModel {
    type: ModelType = ModelType.INVOICE_SECTIONS;
    static type: ModelType = ModelType.INVOICE_SECTIONS;
    prefix = 'invoicing:invoice-section';
    static prefix = 'invoicing:invoice-section';

    get invoiceLines(): InvoiceLineCollection | null {
        return this.relationships?.invoiceLines?.data || null;
    }

    get title(): string | null {
        return this.attributes.title;
    }

    get displayTitle(): boolean {
        return this.attributes.displayTitle || true;
    }

    get displaySubtotal(): boolean {
        return this.attributes.displaySubtotal || true;
    }

    get displayContent(): boolean {
        return this.attributes.displayContent || true;
    }
}
