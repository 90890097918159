import VatRate from '../models/VatRate';
import BaseCollection from '@/app/base/collections/BaseCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import moment from '~~/utils/moment';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import User from '~~/app/auth/models/User';

export default class VatRateCollection extends BaseCollection<VatRate> {
    model: ModelType = ModelType.VAT_RATES;
}
