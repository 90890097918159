import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class InvoiceAnnexPdf extends BaseModel {
    type: ModelType = ModelType.INVOICES_ANNEX_PDFS;
    static type: ModelType = ModelType.INVOICES_ANNEX_PDFS;
    prefix = 'invoicing:invoice-annex-pdf';
    static prefix = 'invoicing:invoice-annex-pdf';

    get documentType(): string | null {
        return this.attributes.document_type;
    }

    get title(): boolean | null {
        return this.attributes.name;
    }

    get default(): boolean | null {
        return this.attributes.default;
    }

    set default(value: boolean | null) {
        this.attributes.default = value;
    }

    get url(): string | null {
        return this.attributes.url;
    }
}
