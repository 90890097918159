import ProductSectionCollection from '../collections/ProductSectionCollection';
import ProductSection from '../models/ProductSection';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class ProductSectionEndpoint extends BaseEndpoint<ProductSection, ProductSectionCollection> {
    model: ModelType = ModelType.PRODUCT_SECTIONS;
    path = 'tenant/inventory/product-sections';
    defaultGetParameters: Object = {
        withCount: 'products',
    };

    // get productsCount(): number {
    //   return this.relationships.products?.meta?.count || 0;
    // }
}
