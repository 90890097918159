import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class ProductImage extends BaseModel {
    type: ModelType = ModelType.PRODUCT_IMAGES;
    static type: ModelType = ModelType.PRODUCT_IMAGES;
    prefix: string = 'inventory:product-images';
    static prefix: string = 'inventory:product-images';

    get name(): string {
        return this.attributes.name;
    }

    get url(): string {
        return this.attributes.url;
    }

    get mediaId(): string {
        return this.attributes.mediaId;
    }
}
