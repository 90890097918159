import User from '~~/app/auth/models/User';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import Contact from '~~/app/contact/models/Contact';
import Product from '~~/app/inventory/models/Product';
import ProductUnit from '~~/app/inventory/models/ProductUnit';
import CalendarEventCollection from '~~/app/planning/collections/CalendarEventCollection';
import Worksite from '~~/app/worksite/models/Worksite';
import moment from '~~/utils/moment';
import InvoiceLineCollection from '../collections/InvoiceLineCollection';
import ExpenseStatus from '../enums/ExpenseStatus';
import VatRate from './VatRate';
import TimeActivity from '~~/app/planning/models/TimeActivity';
import Invoice from './Invoice';
import InvoiceLine from '../../invoicing/models/InvoiceLine';

export default class WorksiteProgressBillingLine extends BaseModel {
    type: ModelType = ModelType.WORKSITE_PROGRESS_BILLING_LINES;
    static type: ModelType = ModelType.WORKSITE_PROGRESS_BILLING_LINES;
    prefix: string = 'worksite:progress-billing-line';
    static prefix: string = 'worksite:progress-billing-line';

    get relationshipsMap(): Record<string, ModelType> {
        return {
            invoiceLine: ModelType.INVOICE_LINES,
        };
    }

    get invoiceLine(): InvoiceLine | null {
        return this.relationships?.invoiceLine?.data || null;
    }

    get originalInvoice(): Invoice | null {
        return this.relationships?.originalInvoice?.data || null;
    }

    get title(): string | null {
        return this.attributes.title;
    }

    get description(): string | null {
        return this.attributes.description;
    }

    get price(): number | null {
        return this.attributes.price;
    }

    get isPercentage(): boolean | null {
        return this.attributes.isPercentage;
    }

    get quantity(): number | null {
        return this.attributes.quantity;
    }

    get lineType(): string {
        return this.attributes.lineType || 'line';
    }

    get vatRate(): VatRate | null {
        return this.relationships?.vatRate?.data || null;
    }

    get product(): Product | null {
        return this.relationships?.product?.data || null;
    }

    get productUnit(): ProductUnit | null {
        return this.relationships?.productUnit?.data || null;
    }

    get total(): number | null {
        return this.attributes.total;
    }
}
