import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { Meta } from 'zhead';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';
import Worksite from '~~/app/worksite/models/Worksite';
import InvoicingDocumentsSettingCollection from '../collections/InvoicingDocumentsSettingCollection';
import InvoicingDocumentsSetting from '../models/InvoicingDocumentsSetting';

export default class InvoicingDocumentsSettingEndpoint extends BaseEndpoint<InvoicingDocumentsSetting, InvoicingDocumentsSettingCollection> {
    model: ModelType = ModelType.INVOICING_DOCUMENTS_SETTINGS;
    path: string = 'tenant/invoicing/invoicing-documents-setting';
}
