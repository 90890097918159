import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

interface UserSchemaAttributes {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    avatar: string;
    locale: string;
    role: string;
    permissions: Record<string, boolean> | null;
    options: Record<string, string> | null;
    isActive: boolean;
}

type UserSchemaMeta = {};
type UserSchemaRelationships = {};

export default class UserSchema extends BaseSchema<UserSchemaAttributes, UserSchemaMeta, UserSchemaRelationships> {
    protected type: ModelType = ModelType.USERS;
}
