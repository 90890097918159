import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class InvoicingNumberingSetting extends BaseModel {
    type: ModelType = ModelType.INVOICING_NUMBERING_SETTINGS;
    static type: ModelType = ModelType.INVOICING_NUMBERING_SETTINGS;
    prefix = 'invoicing:numbering-setting';
    static prefix = 'invoicing:numbering-setting';

    get customPrefix(): string {
        return this.attributes.customPrefix;
    }

    get documentType(): string {
        return this.attributes.documentType;
    }

    get numberPadding(): number {
        return this.attributes.numberPadding;
    }

    get numberPrefix(): string {
        return this.attributes.prefix;
    }

    get customSuffix(): string | null {
        return this.attributes.customSuffix;
    }
}
