import qs from 'qs';
import BaseModel from '../models/BaseModel';
import { Attributes, BaseSchema, Meta, ModelType, Relationships } from '../schemas/BaseSchema';
import BaseCollection from '../collections/BaseCollection';
import { JsonRequestPayload, ModelEndpointResponse, CollectionEndpointResponse, EndpointError } from './types';
import RawEndpoint from './RawEndpoint';
import { useEvent } from '@/composables/useEventBus';
import ModelPrefix from '~~/app/factories/ModelPrefix';
import MixedModelsFactory from '~~/app/factories/MixedModelsFactory';
const runtimeConfig = useRuntimeConfig();

export default abstract class BaseEndpoint<Model extends BaseModel, Collection extends BaseCollection<Model>> extends RawEndpoint {
    /**
     * This should match the model's type property and the JSON:API type.
     */
    abstract model: ModelType;

    /**
     * Include these relationships with every request.
     */
    include = '';

    /**
     * The default GET parameters that must be sent with every request.
     * This can be overridden by the child class, usually to include a "withCount" parameter for example.
     */
    defaultGetParameters: Object = {};

    /**
     * Enable the events to be sent after a request.
     */
    eventsEnabled = true;

    /**
     * Set a callback that should be run everytime this endpoint sends an event.
     */
    onEventSentCallback: Function | null = null;

    setModel(model: ModelType) {
        this.model = model;
        return this;
    }

    setInclude(include: string) {
        this.include = include;
        return this;
    }

    addInclude(extra: string) {
        this.include = this.include ? `${this.include},${extra}` : extra;
        return this;
    }

    withEventCallback(callback: Function) {
        this.onEventSentCallback = callback;
        return this;
    }

    withoutEventCallback() {
        this.onEventSentCallback = null;
        return this;
    }

    withEvents() {
        this.eventsEnabled = true;
        return this;
    }

    withoutEvents() {
        this.eventsEnabled = false;
        return this;
    }

    withDefaultGetParameters(parameters: Object) {
        this.defaultGetParameters = parameters;
        return this;
    }

    withoutDefaultGetParameters() {
        this.defaultGetParameters = {};
        return this;
    }

    /**
     * Makes a fecth request to the API.
     */
    async request<EndpointResponseInterface>(payload: JsonRequestPayload): Promise<EndpointResponseInterface> {
        let response: Response;

        this.trackEvent('endpoint:request', { payload });

        try {
            response = await this.requestRaw(payload);
        } catch (exception: any) {
            return this.exceptionResponse<EndpointResponseInterface>(exception);
        }

        return response.ok ? await this.processResponse<EndpointResponseInterface>(response) : await this.errorResponse<EndpointResponseInterface>(response);
    }

    exceptionResponse<EndpointResponseInterface>(exception: Object | null): EndpointResponseInterface {
        if (exception) {
            console.error(exception);
        }

        const error: EndpointError = {
            status: 500,
            payload: exception,
        };
        const errorResponse: EndpointResponseInterface = {
            data: null,
            response: null,
            error,
        };

        this.trackEvent('endpoint:exception', { error: errorResponse });

        return errorResponse;
    }

    async errorResponse<EndpointResponseInterface>(response: Response): Promise<EndpointResponseInterface> {
        console.warn('An error occured while making the request.', response);

        const json = await response.json();

        const errorResponse: EndpointResponseInterface = {
            data: null,
            response,
            error: json.errors,
        };

        if (response.status == 422 && json.errors && json.errors.length > 0) {
            errorResponse.validationErrors = this.processJsonApiValidationErrors(json.errors);
        }

        this.trackEvent('endpoint:exception', { error: errorResponse });

        return errorResponse;
    }

    processJsonApiValidationErrors(errors: any[]): Record<string, string[]> {
        const validationErrors: Record<string, string[]> = {};

        for (const error of errors) {
            if (error.source && error.source.pointer) {
                const pointer = error.source.pointer;
                const field = this.getJsonApiValidationErrorField(pointer);
                if (validationErrors[field] === undefined) {
                    validationErrors[field] = [];
                }
                validationErrors[field].push(error.detail);
            }
        }

        return validationErrors;
    }

    getJsonApiValidationErrorField(pointer: string): string {
        if (pointer.includes('/data/attributes')) {
            return pointer.replace('/data/attributes/', '');
        }
        if (pointer.includes('/data/relationships')) {
            return pointer.replace('/data/relationships/', '');
        }
        return pointer;
    }

    emptyResponse<EndpointResponseInterface>(response: Response): EndpointResponseInterface {
        const emptyResponse: EndpointResponseInterface = {
            response,
            data: null,
            error: null,
        };

        return emptyResponse;
    }

    /**
     * Process the JSON:API response by using the model's schema to transform the response into a model or a collection of models.
     */
    async processResponse<EndpointResponseInterface>(response: Response): Promise<EndpointResponseInterface> {
        if (response.status === 204) {
            return this.emptyResponse(response);
        }

        const json = await response.json();

        const data: Model | Collection = MixedModelsFactory.make(this.model, json.data, json.included, json.meta || {});

        const successResponse: EndpointResponseInterface = {
            response,
            data,
            error: null,
        };
        return successResponse;
    }

    getDefaultSearchParams(): Object {
        return { ...this.defaultGetParameters, ...{ include: this.include } };
    }

    getSearchParams(params: Object = {}): string {
        return qs.stringify({ ...this.getDefaultSearchParams(), ...params });
    }

    getSearchParamsForSingleModel(params: Object = {}): string {
        const searchParams = { ...this.getDefaultSearchParams(), ...params };
        if (searchParams.sort) {
            delete searchParams.sort;
        }

        return qs.stringify(searchParams);
    }

    async retrieve(uuid: string, params = {}): Promise<ModelEndpointResponse<Model>> {
        const payload: JsonRequestPayload = {
            method: 'GET',
            path: `${uuid}?${this.getSearchParamsForSingleModel(params)}`,
        };

        return await this.request<ModelEndpointResponse<Model>>(payload);
    }

    async index(params = {}): Promise<CollectionEndpointResponse<Model, Collection>> {
        const payload: JsonRequestPayload = {
            method: 'GET',
            path: `?${this.getSearchParams(params)}`,
        };

        return await this.request<CollectionEndpointResponse<Model, Collection>>(payload);
    }

    async store<Schema extends BaseSchema<Attributes, Meta, Relationships>>(schema: Schema): Promise<ModelEndpointResponse<Model>> {
        const payload: JsonRequestPayload = {
            method: 'POST',
            path: `?${this.getSearchParamsForSingleModel()}`,
            data: schema.json(),
        };
        const response = await this.request<ModelEndpointResponse<Model>>(payload);

        if (response.data) {
            this.sendEvent('created', response.data);
        }

        return response;
    }

    async update<Schema extends BaseSchema<Attributes, Meta, Relationships>>(schema: Schema, url: string | null = null, method = 'PATCH'): Promise<ModelEndpointResponse<Model>> {
        if (!schema.id && !url) {
            throw new Error('You need to provide a URL or a schema with an ID to update a model.');
        }

        const payload: JsonRequestPayload = {
            path: `${url || schema.id || ''}?${this.getSearchParamsForSingleModel()}`,
            method,
            data: schema.json(),
        };
        const response = await this.request<ModelEndpointResponse<Model>>(payload);

        if (response.data) {
            this.sendEvent('updated', response.data);
        }

        return response;
    }

    async storeOrUpdate<Schema extends BaseSchema<Attributes, Meta, Relationships>>(schema: Schema, url: string | null = null): Promise<ModelEndpointResponse<Model>> {
        return schema.id ? await this.update(schema, url) : await this.store(schema);
    }

    async destroy(uuid: string): Promise<ModelEndpointResponse<Model>> {
        const payload: JsonRequestPayload = {
            path: `${uuid}?${this.getSearchParamsForSingleModel()}`,
            method: 'DELETE',
        };

        const response = await this.request<ModelEndpointResponse<Model>>(payload);

        if (!response.error) {
            this.sendEvent('deleted', response.data ?? uuid);
        }

        return response;
    }

    async replicate(uuid: string, attributes: any = null): Promise<ModelEndpointResponse<Model>> {
        const payload: JsonRequestPayload = {
            path: `${uuid}/-actions/replicate?${this.getSearchParamsForSingleModel()}`,
            method: 'POST',
            data: {
                data: {
                    id: uuid,
                    type: this.model,
                    attributes,
                },
            },
        };

        const response = await this.request<ModelEndpointResponse<Model>>(payload);

        if (response.data) {
            this.sendEvent('created', response.data);
        }

        return response;
    }

    async customAction<TResponse = Model>(uuid: string | null, action: string, method = 'PATCH', data: any = null): Promise<ModelEndpointResponse<TResponse>> {
        const url = uuid ? `${uuid}/-actions/${action}` : `-actions/${action}`;
        const payload: JsonRequestPayload = {
            path: `${url}?${this.getSearchParamsForSingleModel()}`,
            method,
            data,
        };

        const response = await this.request<ModelEndpointResponse<Model>>(payload);

        if (response.data) {
            this.sendEvent('updated', response.data);
        }

        return response;
    }

    sendEvent(event: string, model: any): void {
        const prefix: string = ModelPrefix.get(this.model);
        const fullEvent = `${prefix}:${event}`;

        if (!this.eventsEnabled) {
            console.warn('Endpoint wanted to send event but they are disabled.', {
                fullEvent,
                model,
            });
            return;
        }

        let eventData = model;
        if (event === 'deleted' && typeof model.isModelOrCollection === 'function') {
            eventData = model.getId();
        }

        // console.log("Endpoint: Sending event", { fullEvent, model, eventData });
        useEvent(fullEvent, eventData);

        if (this.onEventSentCallback) {
            this.onEventSentCallback(fullEvent, model);
        }
    }

    trackEvent(event: string, data: Object): void {
        data = { ...data, ...{ endpoint: this.constructor.name } };

        const { $trackEvent } = useNuxtApp();
        $trackEvent(event, data);
    }
}
