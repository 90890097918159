import TimeActivitySetting from '../models/TimeActivitySetting';
import TimeActivitySettingCollection from '../collections/TimeActivitySettingCollection';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class TimeActivitySettingEndpoint extends BaseEndpoint<TimeActivitySetting, TimeActivitySettingCollection> {
    model: ModelType = ModelType.TIME_ACTIVITY_SETTINGS;
    path = 'tenant/planning/time-activity-settings';
    include = 'relatedTo';
    defaultGetParameters: object = {};
}
