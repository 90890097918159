import Product from '../models/Product';
import BaseCollection from '@/app/base/collections/BaseCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import moment from '~~/utils/moment';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import User from '~~/app/auth/models/User';
import ProductStatus from '../enums/ProductStatus';

export default class ProductCollection extends BaseCollection<Product> {
    model: ModelType = ModelType.PRODUCTS;
}
