import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class WasteCenter extends BaseModel {
    type: ModelType = ModelType.WASTE_CENTER;
    static type: ModelType = ModelType.WASTE_CENTER;
    prefix = 'common:waste-center';
    static prefix = 'common:waste-center';

    get title(): string {
        return this.attributes?.title;
    }

    set title(value: string) {
        this.attributes.title = value;
    }

    get address(): Address | null {
        return this.attributes?.address;
    }

    set address(value: Address | null) {
        this.attributes.address = value;
    }
}
