import { ModelEndpointResponse } from 'app/base/endpoints/types';
import TermsOfServiceCollection from '../collections/TermsOfServiceCollection';
import TermsOfService from '../models/TermsOfService';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '@/app/base/schemas/BaseSchema';

export default class TermsOfServiceEndpoint extends BaseEndpoint<TermsOfService, TermsOfServiceCollection> {
    model: ModelType = ModelType.TERMS_OF_SERVICES;
    path = 'tenant/common/terms-of-services';

    async latest(): Promise<ModelEndpointResponse<TermsOfService>> {
        return await this.customAction(null, 'latest', 'GET');
    }
}
