import { Meta } from 'zhead';
import NotificationLogCollection from '../collections/NotificationLogCollection';
import NotificationLog from '../models/NotificationLog';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';
import Worksite from '~~/app/worksite/models/Worksite';

export default class NotificationLogEndpoint extends BaseEndpoint<NotificationLog, NotificationLogCollection> {
    model: ModelType = ModelType.NOTIFICATIONS_LOGS;
    path = 'tenant/common/notifications-log';
}
