import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { Meta } from 'zhead';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';
import WorksitePhaseCollection from '../collections/WorksitePhaseCollection';
import WorksitePhase from '../models/WorksitePhase';

export default class WorksitePhaseEndpoint extends BaseEndpoint<WorksitePhase, WorksitePhaseCollection> {
    model: ModelType = ModelType.WORKSITE_PHASES;
    path: string = 'tenant/worksite/worksite-phases';
}
