import InvoiceAutosave from '../models/InvoiceAutosave';
import BaseCollection from '@/app/base/collections/BaseCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import moment from '~~/utils/moment';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import User from '~~/app/auth/models/User';
import InvoiceAutosaveStatus from '../enums/InvoiceAutosaveStatus';

export default class InvoiceAutosaveCollection extends BaseCollection<InvoiceAutosave> {
    model: ModelType = ModelType.INVOICE_AUTOSAVES;
}
