import Contact from '@/app/contact/models/Contact';
import Lead from '@/app/crm/models/Lead';
import Invoice from '@/app/invoicing/models/Invoice';
import CalendarEvent from '@/app/planning/models/CalendarEvent';
import Task from '@/app/task/models/Task';
import Worksite from '@/app/worksite/models/Worksite';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class GlobalSearchItem extends BaseModel {
    type: ModelType = ModelType.GLOBAL_SEARCH_ITEMS;
    static type: ModelType = ModelType.GLOBAL_SEARCH_ITEMS;
    prefix: string = 'common:global-search-items';
    static prefix: string = 'common:global-search-items';

    get contact(): Contact | null {
        return this.relationships?.contact?.data ?? null;
    }

    get model(): Invoice | Worksite | Task | Lead | CalendarEvent | null {
        return this.relationships?.model?.data ?? null;
    }

    get invoice(): Invoice | null {
        if (this.modelType == 'invoice') {
            return this.model as Invoice;
        }
        return null;
    }

    get worksite(): Worksite | null {
        if (this.modelType == 'worksite') {
            return this.model as Worksite;
        }
        return null;
    }

    get lead(): Lead | null {
        if (this.modelType == 'lead') {
            return this.model as Lead;
        }
        return null;
    }

    get calendarEvent(): CalendarEvent | null {
        if (this.modelType == 'calendar-event') {
            return this.model as CalendarEvent;
        }
        return null;
    }

    get task(): Task | null {
        if (this.modelType == 'task') {
            return this.model as Task;
        }
        return null;
    }

    get title(): string {
        return this.attributes.title;
    }

    get searchableFields(): string[] | null {
        return this.attributes.searchableFields;
    }

    get modelType(): string {
        return this.attributes.modelType;
    }
}
