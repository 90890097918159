import { Meta } from 'zhead';
import ExpenseCollection from '../collections/ExpenseCollection';
import Expense from '../models/Expense';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { JsonRequestPayload, ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';
import Worksite from '~~/app/worksite/models/Worksite';

export type ExpenseAccountingIdValidation = {
    isAvailable: boolean;
};
export default class ExpenseEndpoint extends BaseEndpoint<Expense, ExpenseCollection> {
    model: ModelType = ModelType.EXPENSES;
    path = 'tenant/invoicing/expenses';
    include = 'contact,worksite';

    async validateAccountingId(accountingId: string, expense?: Expense): Promise<ExpenseAccountingIdValidation|void>
    {
        const payload: JsonRequestPayload = {
            method: 'POST',
            data: {
                data: {
                    type: 'expense-validate-accounting-id',
                    attributes: {
                        accountingId,
                        expense: expense?.getId()
                    },
                },
            },
            path: `-actions/validate/accounting-id`,
        };

        const response = await this.requestRaw(payload);
        const { data } = await response.json();
        if (!data?.attributes) return;

        const validationResponse: ExpenseAccountingIdValidation = {
            isAvailable: data?.attributes?.isAvailable,
        };
        return validationResponse;
    }
}
