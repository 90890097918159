import WasteCenterCollection from '../collections/WasteCenterCollection';
import WasteCenter from '../models/WasteCenter';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class WasteCenterEndpoint extends BaseEndpoint<WasteCenter, WasteCenterCollection> {
    model: ModelType = ModelType.WASTE_CENTER;
    path = 'tenant/common/waste-centers';
    include = '';
}
