import User from '~~/app/auth/models/User';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import Contact from '~~/app/contact/models/Contact';
import CalendarEventCollection from '~~/app/planning/collections/CalendarEventCollection';
import Worksite from '~~/app/worksite/models/Worksite';
import moment from '~~/utils/moment';
import ProductUnitStatus from '../enums/ProductUnitStatus';

export default class ProductUnit extends BaseModel {
    type: ModelType = ModelType.PRODUCT_UNITS;
    static type: ModelType = ModelType.PRODUCT_UNITS;
    prefix: string = 'inventory:product-unit';
    static prefix: string = 'inventory:product-unit';

    get title(): string {
        return this.attributes.title;
    }
}
