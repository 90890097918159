export const CalendarEventTypeValue = ['worksite', 'appointment', 'intervention', 'holiday', 'bank_holiday', 'sick', 'bad_weather', 'other'] as const;

export class CalendarEventType {
    static WORKSITE = new CalendarEventType('worksite');
    static APPOINTMENT = new CalendarEventType('appointment');
    static INTERVENTION = new CalendarEventType('intervention');
    static HOLIDAY = new CalendarEventType('holiday');
    static SICK = new CalendarEventType('sick');
    static BANK_HOLIDAY = new CalendarEventType('bank_holiday');
    static BAD_WEATHER = new CalendarEventType('bad_weather');
    static OTHER = new CalendarEventType('other');

    public name: typeof CalendarEventTypeValue;

    constructor(name: typeof CalendarEventTypeValue) {
        this.name = name;
    }

    title(): string {
        const { t } = useTranslation();
        return t(`calendar_event_types.${this.name}`);
    }

    color(): string {
        switch (this.name) {
            case 'worksite':
                return '#10a4d3';
            case 'appointment':
                return '#6c5ce7';
            case 'intervention':
                return '#e2970a';
            case 'holiday':
            case 'bank_holiday':
                return '#00b894';
            case 'sick':
            case 'bad_weather':
                return '#d63031';
            default:
                return '#95a5a6';
        }
    }

    icon(): string {
        switch (this.name) {
            case 'worksite':
                return 'hard-hat';
            case 'appointment':
                return 'clock';
            case 'intervention':
                return 'wrench';
            case 'holiday':
                return 'calendar-check';
            case 'bank_holiday':
                return 'island-tropical';
            case 'sick':
                return 'face-thermometer';
            case 'bad_weather':
                return 'cloud-rain';
            default:
                return 'circle';
        }
    }
}
