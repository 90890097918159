import ProductCategory from '../models/ProductCategory';
import ProductCategoryStatus from '../enums/ProductCategoryStatus';
import BaseCollection from '@/app/base/collections/BaseCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import moment from '~~/utils/moment';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import User from '~~/app/auth/models/User';

export default class ProductSectionCollection extends BaseCollection<ProductSection> {
    model: ModelType = ModelType.PRODUCT_SECTIONS;
}
