import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import InvoiceTemplateSectionColumnCollection from '../collections/InvoiceTemplateSectionColumnCollection';

export default class InvoiceTemplateSection extends BaseModel {
    type: ModelType = ModelType.INVOICE_TEMPLATE_SECTIONS;
    static type: ModelType = ModelType.INVOICE_TEMPLATE_SECTIONS;
    prefix: string = 'invoicing:invoice-template-sections';
    static prefix: string = 'invoicing:invoice-template-sections';

    get invoiceTemplateSectionColumns(): InvoiceTemplateSectionColumnCollection | null {
        return this.relationships?.invoiceTemplateSectionColumns?.data || null;
    }

    get title(): string {
        return this.attributes.title;
    }

    get content(): string | null {
        return this.attributes.content;
    }

    get style(): object {
        return this.attributes.style;
    }

    get beforeTable(): boolean {
        return this.attributes.beforeTable;
    }

    get order(): number {
        return this.attributes.order;
    }
}
