import ProductImageCollection from '../collections/ProductImageCollection';
import ProductImage from '../models/ProductImage';
import { ModelEndpointResponse } from '@/app/base/endpoints/types';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { Attributes, BaseSchema, Meta, ModelType, Relationships } from '@/app/base/schemas/BaseSchema';

export default class ProductImageEndpoint extends BaseEndpoint<ProductImage, ProductImageCollection> {
    model: ModelType = ModelType.PRODUCT_IMAGES;
    path = 'tenant/inventory/product-images';

    async upload<Schema extends BaseSchema<Attributes, Meta, Relationships>>(schema: Schema): Promise<ModelEndpointResponse<ProductImage>> {
        const response = await this.customAction(null, 'upload', 'POST', {
            data: schema,
        });

        if (response.data) {
            this.sendEvent('created', response.data);
        }

        return response;
    }
}
