import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type TimeActivityRelationships = {
    user: ToOneRelationship<ModelType.USERS>;
    worksite: ToOneRelationship<ModelType.WORKSITES>;
    worksitePhase: ToOneRelationship<ModelType.WORKSITE_PHASES>;
    task: ToOneRelationship<ModelType.TASKS>;
    timeActivityPauses: ToManyRelationship<ModelType.TIME_ACTIVITY_PAUSES>;
    timeActivityGeolocations: ToManyRelationship<ModelType.TIME_ACTIVITY_GEOLOCATIONS>;
    tasks: ToManyRelationship<ModelType.TASKS>;
};

type TimeActivityAttributes = {
    title: string;
    startsAt: string;
    endsAt?: string | null;
    invoicedAt?: string | null;
};

type TimeActivityMeta = {
    status: string;
    costs: TimeActivityCosts;
    liveTimer: TimeActivityLiverTimer;
};

type TimeActivityCosts = {
    hours: number;
    hourlyCost: number | null;
    totalCost: number | null;
    hourlyRate: number | null;
    totalRate: number | null;
};

type TimeActivityLiverTimer = {
    loadedAt: number;
    duration: number;
};

export default class TimeActivitySchema extends BaseSchema<TimeActivityAttributes, TimeActivityMeta, TimeActivityRelationships> {
    protected type: ModelType = ModelType.TIME_ACTIVITIES;
}
