import TimeActivityCollection from '../collections/TimeActivityCollection';
import TimeActivity from '../models/TimeActivity';
import TimeActivitySchema from '../schemas/TimeActivitySchema';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import moment from '~~/utils/moment';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import Invoice from '~~/app/invoicing/models/Invoice';

export default class TimeActivityEndpoint extends BaseEndpoint<TimeActivity, TimeActivityCollection> {
    model: ModelType = ModelType.TIME_ACTIVITIES;
    path = 'tenant/planning/time-activities';
    include = 'user,worksite,worksitePhase,task,invoice';
    defaultGetParameters: object = {
        sort: 'startsAt',
    };

    async start(): Promise<ModelEndpointResponse<TimeActivity>> {
        const { authed } = useAuthStore();
        const { t } = useTranslation();

        const schema = TimeActivitySchema.make({
            attributes: {
                title: authed?.timeActivityDefaultTitle || t('misc.time_activity_default_title'),
                startsAt: moment().toUtcDateTimeString(),
                endsAt: null,
            },
        });

        if (authed && authed.getId()) {
            schema.addToOneRelationship('user', ModelType.USERS, authed.getId());
        }

        return await this.store(schema);
    }

    async stop(uuid: string): Promise<ModelEndpointResponse<TimeActivity>> {
        return await this.customAction(uuid, 'stop');
    }

    async pause(uuid: string): Promise<ModelEndpointResponse<TimeActivity>> {
        return await this.customAction(uuid, 'pause');
    }

    async restart(uuid: string): Promise<ModelEndpointResponse<TimeActivity>> {
        return await this.customAction(uuid, 'restart');
    }

    async markAsInvoiced(uuid: string): Promise<ModelEndpointResponse<TimeActivity>> {
        const schema = TimeActivitySchema.make({
            id: uuid,
            attributes: {
                invoicedAt: moment().toUtcDateTimeString(),
            },
        });

        return await this.update(schema);
    }

    async unmarkAsInvoiced(uuid: string): Promise<ModelEndpointResponse<TimeActivity>> {
        return await this.customAction(uuid, 'unmark-as-invoiced');
    }

    async transformToInvoice(uuid: string, invoiceUuid?: string): Promise<ModelEndpointResponse<Invoice>> {
        const data = {
            type: 'time-activities',
            id: uuid,
            attributes: {},
        };
        if (invoiceUuid) {
            data.attributes.invoice = {
                type: 'invoices',
                id: invoiceUuid,
            };
        }

        return await this.customAction(uuid, 'transform/invoice', 'POST', {
            data,
        });
    }

    async transformActivitiesToInvoice(uuids: string[]): Promise<ModelEndpointResponse<Invoice>> {
        const data = uuids.map((uuid) => ({ id: uuid }));

        return await this.customAction(null, 'transform/activities/invoice', 'POST', {
            data,
        });
    }
}
