import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { Meta } from 'zhead';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';
import Worksite from '~~/app/worksite/models/Worksite';
import InvoicingNotificationsSettingCollection from '../collections/InvoicingNotificationsSettingCollection';
import InvoicingNotificationsSetting from '../models/InvoicingNotificationsSetting';

export default class InvoicingNotificationsSettingEndpoint extends BaseEndpoint<InvoicingNotificationsSetting, InvoicingNotificationsSettingCollection> {
    model: ModelType = ModelType.INVOICING_NOTIFICATIONS_SETTINGS;
    path: string = 'tenant/invoicing/invoicing-notifications-setting';
}
