import InvoiceAnnexPdfCollection from '../collections/InvoiceAnnexPdfCollection';
import InvoiceAnnexPdf from '../models/InvoiceAnnexPdf';
import { InvoiceDocumentType } from '../enums/InvoiceDocumentType';
import { ModelEndpointResponse } from '@/app/base/endpoints/types';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class InvoiceAnnexPdfEndpoint extends BaseEndpoint<InvoiceAnnexPdf, InvoiceAnnexPdfCollection> {
    model: ModelType = ModelType.INVOICES_ANNEX_PDFS;
    path = 'tenant/invoicing/invoice-annex-pdfs';

    async upload(
        fields: {
            file: File;
            documentType: InvoiceDocumentType;
            isDefault: boolean;
        },
        checked: boolean
    ): Promise<ModelEndpointResponse<InvoiceAnnexPdf>> {
        const data = new FormData();
        const isDefault = Number(fields.isDefault);
        data.append('data[type]', 'invoice-annex-pdfs');
        data.append('data[attributes][documentType]', fields.documentType);
        data.append('data[attributes][file]', fields.file);
        data.append('data[attributes][default]', isDefault);
        const response = await this.customAction(null, 'upload', 'POST', data);
        this.sendEvent('created', response.data);
        return response;
    }

    async markAsDefault(uuid: string, isDefault: boolean): Promise<ModelEndpointResponse<InvoiceAnnexPdf>> {
        const defaultValue = Number(isDefault);

        return await this.customAction(uuid, 'mark-default', 'PATCH', {
            data: {
                type: 'invoice-annex-pdfs',
                id: uuid,
                attributes: {
                    default: defaultValue,
                },
            },
        });
    }

    // async markAs(
    //   uuid: string,
    //   status: string,
    // ): Promise<ModelEndpointResponse<Invoice>> {
    //   return await this.customAction(uuid, status);
    // }
}
