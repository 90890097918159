import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import Worksite from '../../worksite/models/Worksite';

export default class ModelFileFolder extends BaseModel {
    type: ModelType = ModelType.MODEL_FILE_FOLDERS;
    static type: ModelType = ModelType.MODEL_FILE_FOLDERS;
    prefix: string = 'common:model-file-folder';
    static prefix: string = 'common:model-file-folder';

    get worksite(): Worksite | null {
        return this.relationships?.worksite?.data || null;
    }

    get parent(): ModelFileFolder | null {
        return this.relationships?.parent?.data || null;
    }

    get title(): string {
        return this.attributes.title;
    }

    get modelFilesCount(): number {
        return this.relationships.modelFiles?.meta?.count || 0;
    }
}
