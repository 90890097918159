import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { Meta } from 'zhead';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';
import Worksite from '~~/app/worksite/models/Worksite';
import ProductUnitCollection from '../collections/ProductUnitCollection';
import ProductUnit from '../models/ProductUnit';

export default class ProductUnitEndpoint extends BaseEndpoint<ProductUnit, ProductUnitCollection> {
    model: ModelType = ModelType.PRODUCT_UNITS;
    path: string = 'tenant/inventory/product-units';
}
