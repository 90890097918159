import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

interface GoogleMapMarkerSize {
    width: number;
    height: number;
}

export default class TimeActivityGeolocation extends BaseModel {
    type: ModelType = ModelType.TIME_ACTIVITY_GEOLOCATIONS;
    static type: ModelType = ModelType.TIME_ACTIVITY_GEOLOCATIONS;
    prefix = 'planning:time-activity-geolocation';
    static prefix = 'planning:time-activity-geolocation';

    get latitude(): number {
        return this.attributes!.latitude;
    }

    get longitude(): number {
        return this.attributes!.longitude;
    }

    get event(): 'start' | 'end' | 'pool' {
        return this.attributes!.event;
    }

    /**
     * This file needs to be in the public directory.
     * Because Nuxt 3 doesn't have an easy way to access an asset with a dynamic name (see: https://github.com/nuxt/nuxt/issues/14766).
     */
    getIconUrl(): string {
        return `/google-maps/markers/${this.event}.svg`;
    }

    getIconSize(): GoogleMapMarkerSize {
        if (this.event === 'pool') {
            return {
                width: 18,
                height: 18,
            };
        }

        return {
            width: 36,
            height: 36,
        };
    }
}
