import LoginQrCodeCollection from '../collections/LoginQrCodeCollection';
import LoginQrCode from '../models/LoginQrCode';
import { JsonRequestPayload } from '../../base/endpoints/types';
import User from '../models/User';
import UserSchema from '../schemas/UserSchema';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export interface MagicLinkResponse {
    response: Response;
    validationErrors: Record<string, string[]> | null;
    json: any;
}

export interface ValidatedMagicLinkResponse {
    token?: string | null;
    user?: User | null;
    response: Response;
    validationErrors: Record<string, string[]> | null;
    json: any;
}
export default class LoginMagicLinkEndpoint extends BaseEndpoint<LoginQrCode, LoginQrCodeCollection> {
    model: ModelType = ModelType.LOGIN_MAGIC_LINK;
    path = 'auth/login-magic-link';

    async generate(email: string): Promise<MagicLinkResponse> {
        const payload: JsonRequestPayload = {
            method: 'POST',
            data: {
                data: {
                    email,
                },
            },
            path: 'generate',
        };

        const response = await this.requestRaw(payload);
        const json = await response.json();
        const magicLinkResponse: MagicLinkResponse = {
            response,
            json,
        };

        if (!response.ok) {
            useToasteoError();
            console.error('Verification failed.', response, magicLinkResponse);
            return magicLinkResponse;
        }

        return magicLinkResponse;
    }

    async validated(email: string, code: string): Promise<ValidatedMagicLinkResponse> {
        const payload: JsonRequestPayload = {
            method: 'POST',
            data: {
                data: {
                    email,
                    code,
                },
            },
            path: 'validated',
        };

        const response = await this.requestRaw(payload);
        const json = await response.json();
        const magicLinkResponse: ValidatedMagicLinkResponse = {
            response,
            json,
        };

        if (!response.ok) {
            useToasteoError();
            console.error('Failed to login.', response, magicLinkResponse);
            return magicLinkResponse;
        }
        magicLinkResponse.user = this.getUserFromResponse(json);
        magicLinkResponse.token = this.getTokenFromResponse(json);
        return magicLinkResponse;
    }

    getTokenFromResponse(json: any): string | undefined {
        if (!json.data.attributes.token) {
            return undefined;
        }
        return `${json.data.attributes.token.token_type} ${json.data.attributes.token.access_token}`;
    }

    getUserFromResponse(json: any): User | undefined {
        if (!json.data.attributes.user) {
            return undefined;
        }

        return new User(
            new UserSchema({
                attributes: camelCaseKeys(json.data.attributes.user),
                id: json.data.attributes.user.id,
            })
        );
    }
}
