import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { Meta } from 'zhead';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';
import ModelFileCollection from '../collections/ModelFileCollection';
import ModelFile from '../models/ModelFile';
import ModelFileSchema from '../schemas/ModelFileSchema';
import EndpointFactory from '@/app/factories/EndpointFactory';

export default class ModelFileEndpoint extends BaseEndpoint<ModelFile, ModelFileCollection> {
    model: ModelType = ModelType.MODEL_FILES;
    path: string = 'tenant/common/model-files';
    include: string = 'user';

    async move(id: string, folderId: string | null): Promise<ModelEndpointResponse<ModelFile>> {
        useEvent('common:model-file:deleted', id);

        const schema = new ModelFileSchema({ id });
        schema.addToOneRelationship('modelFileFolder', ModelType.MODEL_FILE_FOLDERS, folderId);
        const response = await this.update(schema);

        if (response.data) {
            useToasteoSuccess();
        }

        if (!folderId) {
            return response;
        }

        const folderEndpoint = EndpointFactory.make(ModelType.MODEL_FILE_FOLDERS);
        const folderResponse = await folderEndpoint.retrieve(folderId);
        if (folderResponse.data) {
            useEvent('common:model-file-folder:updated', folderResponse.data);
        }

        return response;
    }

    async upload<Schema extends BaseSchema<Attributes, Meta, Relationships>>(schema: Schema, media: File): Promise<ModelEndpointResponse<ModelFile>> {
        this.eventsEnabled = false;

        let response = await this.store(schema);

        if (!response.data) {
            return response;
        }

        this.eventsEnabled = true;

        let data = new FormData();
        data.append('data[type]', 'model-files');
        data.append('data[id]', response.data.getId());
        data.append('data[attributes][media]', media);
        let response2 = await this.customAction(response.data.getId(), 'upload', 'POST', data);

        if (!response2.data) {
            await this.destroy(response.data.getId());
        } else {
            this.sendEvent('created', response2.data);
        }

        return response2;
    }
}
