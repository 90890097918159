import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { Meta } from 'zhead';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';
import ModelFileFolderCollection from '../collections/ModelFileFolderCollection';
import ModelFileFolder from '../models/ModelFileFolder';

export default class ModelFileFolderEndpoint extends BaseEndpoint<ModelFileFolder, ModelFileFolderCollection> {
    model: ModelType = ModelType.MODEL_FILE_FOLDERS;
    path: string = 'tenant/common/model-file-folders';
    defaultGetParameters: Object = {
        withCount: 'modelFiles',
    };
}
