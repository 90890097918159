import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import moment from '~~/utils/moment';

export default class Workfield extends BaseModel {
    type: ModelType = ModelType.WORKFIELDS;
    static type: ModelType = ModelType.WORKFIELDS;
    prefix: string = 'common:workfields';
    static prefix: string = 'common:workfields';

    get title(): string {
        const { t } = useTranslation();
        return t(`workfields.${this.key}`);
    }

    get titleRaw(): string {
        return this.attributes.title;
    }

    get key(): string {
        return this.attributes.key;
    }

    get icon(): string {
        switch (this.key) {
            case 'roofing':
                return 'fa-chimney';
            case 'heating':
                return 'fa-fire';
            case 'plumbing':
                return 'fa-tint';
            case 'building':
                return 'fa-home';
            case 'windows':
                return 'fa-window-maximize';
            case 'carpentry':
                return 'fa-hammer';
            case 'exteriors':
                return 'fa-house-tree';
            case 'flooring':
                return 'fa-table-cells';
            case 'walls':
                return 'fa-paint-roller';
            case 'insulation':
                return 'fa-thermometer-half';
            default:
                return 'fa-wrench';
        }
    }
}
