import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { Meta } from 'zhead';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';
import Worksite from '~~/app/worksite/models/Worksite';
import InvoicingPaymentRemindersSettingCollection from '../collections/InvoicingPaymentRemindersSettingCollection';
import InvoicingPaymentRemindersSetting from '../models/InvoicingPaymentRemindersSetting';

export default class InvoicingPaymentRemindersSettingEndpoint extends BaseEndpoint<InvoicingPaymentRemindersSetting, InvoicingPaymentRemindersSettingCollection> {
    model: ModelType = ModelType.INVOICING_PAYMENT_REMINDERS_SETTINGS;
    path: string = 'tenant/invoicing/invoicing-payment-reminders-settings';
}
