import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { Meta } from 'zhead';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';
import Worksite from '~~/app/worksite/models/Worksite';
import ProductCategoryCollection from '../collections/ProductCategoryCollection';
import ProductCategory from '../models/ProductCategory';

export default class ProductCategoryEndpoint extends BaseEndpoint<ProductCategory, ProductCategoryCollection> {
    model: ModelType = ModelType.PRODUCT_CATEGORIES;
    path: string = 'tenant/inventory/product-categories';
}
