import { BankAccountCounterpart, BankAccountTransactionCommunication, BankAccountTransactionType } from '../schemas/BankAccountTransactionSchema';
import Invoice from '~/app/invoicing/models/Invoice';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType, ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

export default class BankAccountTransaction extends BaseModel {
    type: ModelType = ModelType.BANK_ACCOUNT_TRANSACTIONS;
    static type: ModelType = ModelType.BANK_ACCOUNT_TRANSACTIONS;
    prefix = 'bankaccount:bank-account-transactions';
    static prefix = 'bankaccount:bank-account-transactions';

    get executedAt(): moment.Moment {
        return moment(this.attributes.executedAt);
    }

    get amount(): number {
        return this.attributes.amount;
    }

    get counterpart(): BankAccountCounterpart {
        return this.meta.counterpart;
    }

    get counterpartIban(): BankAccountCounterpart['iban'] {
        return this.attributes.counterpartIban;
    }

    get communication(): BankAccountTransactionCommunication {
        return this.meta.communication;
    }

    get communicationValue(): BankAccountTransactionCommunication['value'] | null {
        return this.communication?.value ?? null;
    }

    get transactionType(): BankAccountTransactionType {
        return this.attributes.type;
    }

    get invoice() {
        const { data } = this.relationships.invoice as any as { data: null } | { data: Invoice };

        return data || undefined;
    }
}
