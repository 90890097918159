import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { Meta } from 'zhead';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';
import Worksite from '~~/app/worksite/models/Worksite';
import InvoicingNumberingSettingCollection from '../collections/InvoicingNumberingSettingCollection';
import InvoicingNumberingSetting from '../models/InvoicingNumberingSetting';

export default class InvoicingNumberingSettingEndpoint extends BaseEndpoint<InvoicingNumberingSetting, InvoicingNumberingSettingCollection> {
    model: ModelType = ModelType.INVOICING_NUMBERING_SETTINGS;
    path: string = 'tenant/invoicing/invoicing-numbering-setting';
}
