import WorksiteProgressBilling from '../models/WorksiteProgressBilling';
import WorksiteProgressBillingCollection from '../collections/WorksiteProgressBillingCollection';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';

export default class WorksiteProgressBillingLineEndpoint extends BaseEndpoint<WorksiteProgressBilling, WorksiteProgressBillingCollection> {
    model: ModelType = ModelType.WORKSITE_PROGRESS_BILLINGS;
    path = 'tenant/worksite/worksite-progress-billings';
    include = 'quote,worksiteProgressBillingLines.invoiceLine';
}
