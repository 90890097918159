import TenantCollection from '../auth/collections/TenantCollection';
import UserCollection from '../auth/collections/UserCollection';
import Tenant from '../auth/models/Tenant';
import User from '../auth/models/User';
import BaseCollection from '../base/collections/BaseCollection';
import { JsonResponse } from '../base/endpoints/types';
import BaseModel from '../base/models/BaseModel';
import { BaseSchema, ModelType } from '../base/schemas/BaseSchema';
import TimeActivityCollection from '../planning/collections/TimeActivityCollection';
import TimeActivity from '../planning/models/TimeActivity';
import WorksiteCollection from '../worksite/collections/WorksiteCollection';
import CollectionFactory from './CollectionFactory';
import ModelFactory from './ModelFactory';

export default class MixedModelsFactory {
    static make(type: ModelType, schemas: any, included: any, meta: Object = {}) {
        return Array.isArray(schemas) ? CollectionFactory.make(type, schemas, included, meta) : ModelFactory.make(type, schemas, included);
    }
}
