import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class InvoiceTemplateSectionColumn extends BaseModel {
    type: ModelType = ModelType.INVOICE_TEMPLATE_SECTION_COLUMNS;
    static type: ModelType = ModelType.INVOICE_TEMPLATE_SECTION_COLUMNS;
    prefix: string = 'invoicing:invoice-template-section-columns';
    static prefix: string = 'invoicing:invoice-template-section-columns';

    get title(): string {
        return this.attributes.title;
    }

    get content(): string {
        return this.attributes.content;
    }

    get style(): object {
        return this.attributes.style;
    }

    get order(): number {
        return this.attributes.order;
    }
}
