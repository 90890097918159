import NotificationCollection from '../collections/NotificationCollection';
import Notification from '../models/Notification';
import { CollectionEndpointResponse } from '@/app/base/endpoints/types';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '@/app/base/schemas/BaseSchema';
type UnreadCountActionResponse = {
    attributes: { count: number };
};
export default class NotificationEndpoint extends BaseEndpoint<Notification, NotificationCollection> {
    model: ModelType = ModelType.NOTIFICATIONS;
    path = 'common/notifications';
    getDefaultSearchParams() {
        const { authed } = useAuthStore();
        const { getValue } = useLocalStorage<boolean>('notifications-assigned-filter');
        return {
            filter: {
                user: authed?.getId(),
                assigned: getValue() || false,
            },
        };
    }

    async getUnreadCount() {
        const response = await this.customAction<UnreadCountActionResponse>(null, 'unread-count', 'get');
        return response.data?.attributes.count;
    }

    markAsRead() {
        return this.customAction<CollectionEndpointResponse<Notification, NotificationCollection>>(null, 'mark-as-read', 'put');
    }
}
