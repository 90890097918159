import ProductUnit from './ProductUnit';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class ComposedWorkLine extends BaseModel {
    type: ModelType = ModelType.COMPOSED_WORK_LINES;
    static type: ModelType = ModelType.COMPOSED_WORK_LINES;
    prefix: string = 'inventory:composed-work-lines';
    static prefix: string = 'inventory:composed-work-lines';

    get productUnit(): ProductUnit | null {
        return this.relationships?.productUnit?.data;
    }

    get title(): string {
        return this.attributes.title;
    }

    get price(): number {
        return this.attributes.price;
    }

    get purchasePrice(): number {
        return this.attributes.purchasePrice;
    }

    get quantity(): number {
        return this.attributes.quantity;
    }
}
