import GlobalSearchItemCollection from '../collections/GlobalSearchItemCollection';
import GlobalSearchItem from '../models/GlobalSearchItem';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '@/app/base/schemas/BaseSchema';

export default class GlobalSearchItemEndpoint extends BaseEndpoint<GlobalSearchItem, GlobalSearchItemCollection> {
    model: ModelType = ModelType.GLOBAL_SEARCH_ITEMS;
    path = 'tenant/common/global-search-items';
    include = 'model,contact';
}
