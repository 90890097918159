export default class Media {
    appKey: string | null;
    collection: string;
    conversions: Array<any> | null;
    customProperties: Object | null;
    height: number | null;
    id: number;
    modelId: string | number | null;
    modelType: string | null;
    name: string | null;
    optimized: Object | null;
    url: string;
    uuid: string;
    width: number | null;

    static make(data: MediaInterface): Media {
        return new Media(data);
    }

    constructor(data: MediaInterface) {
        this.appKey = data.appKey;
        this.collection = data.collection;
        this.conversions = data.conversions;
        this.customProperties = data.customProperties;
        this.height = data.height;
        this.id = data.id;
        this.modelId = data.modelId;
        this.modelType = data.modelType;
        this.name = data.name;
        this.optimized = data.optimized;
        this.url = data.url;
        this.uuid = data.uuid;
        this.width = data.width;
    }
}
