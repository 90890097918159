import InvoiceDiscountCollection from '../collections/InvoiceDiscountCollection';
import InvoiceDiscount from '../models/InvoiceDiscount';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '@/app/base/schemas/BaseSchema';

export default class InvoiceDiscountEndpoint extends BaseEndpoint<InvoiceDiscount, InvoiceDiscountCollection> {
    model: ModelType = ModelType.INVOICE_DISCOUNTS;
    path = 'tenant/invoicing/invoice-discounts';

    /**
     * We want to refresh the invoice's data after a payment is created, updated or deleted.
     * Because the totalDue, totalPaid and status might have changed.
     */
    onEventSentCallback: Function | null = (event: string, model: InvoiceDiscount) => {
        if (model.invoice) {
            useEvent('invoicing:invoice:updated', model.invoice);
        }
    };
}
