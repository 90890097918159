import BankAccount from '../models/BankAccount';
import BankAccountCollection from '../collections/BankAccountCollection';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class BankAccountTransactionEndpoint extends BaseEndpoint<BankAccount, BankAccountCollection> {
    model: ModelType = ModelType.BANK_ACCOUNT_TRANSACTIONS;
    path = 'tenant/bankaccount/bank-account-transactions';
    include = 'invoice';
}
