import { ModelType } from '@/app/base/schemas/BaseSchema';
import RawEndpoint from '~/app/base/endpoints/RawEndpoint';

export default class ConnectionsEndpoint extends RawEndpoint {
    model: ModelType = ModelType.CONNECTIONS;
    path = 'tenant/common';

    async storeConnectionEntry(): Promise<void> {
        const getRequestHeaders = (): HeadersInit => {
            const { usableToken, authed, tenant } = useAuthStore();
            const headers: HeadersInit = new Headers();
            headers.set('Content-Type', 'application/vnd.api+json');
            headers.set('Accept', 'application/vnd.api+json');
            headers.set('Content-Language', authed?.locale || 'en');
            headers.set('Authorization', usableToken);
            headers.set('X-Tenant-ID', tenant?.getId());
            return headers;
        };
        const payload = {
            method: 'POST',
            path: 'connections',
            headers: getRequestHeaders(),
            data: {
                data: {
                    connectionType: 'web',
                },
            },
        };

        const response = await this.requestRaw(payload);
        const { errors } = await response.json();
        if (errors && errors.length > 0) {
            // eslint-disable-next-line no-console
            console.log(errors);
        }
    }
}
