import type { NotificationType } from '~~/app/common/enums/NotificationType';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class Notification extends BaseModel {
    type: ModelType = ModelType.NOTIFICATIONS;
    static type: ModelType = ModelType.NOTIFICATIONS;
    prefix = 'common:notifications';
    static prefix = 'common:notifications';

    get title(): string {
        return this.attributes.title;
    }

    get text(): string | null {
        return this.attributes.text;
    }

    get notificationType(): NotificationType {
        return this.attributes.type;
    }

    get readAt(): string {
        return this.attributes.readAt;
    }

    get icon(): string | null {
        return this.attributes.icon;
    }

    get tenantId(): string {
        return this.attributes.tenantId;
    }

    get relatedToId(): string | null {
        return this.attributes.relatedToId;
    }
}
