import Source from '../models/Source';
import SourceCollection from '../collections/SourceCollection';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';

export default class SourceEndpoint extends BaseEndpoint<Source, SourceCollection> {
    model: ModelType = ModelType.SOURCES;
    path = 'tenant/crm/sources';
    include = '';
}
