import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class LoginMagicLink extends BaseModel {
    type: ModelType = ModelType.LOGIN_MAGIC_LINK;
    static type: ModelType = ModelType.LOGIN_MAGIC_LINK;
    prefix = 'auth:login-magic-link';
    static prefix = 'auth:login-magic-link';
    // TBD;
    // get qrCode(): string {
    //   return this.meta.qrCode;
    // }
}
