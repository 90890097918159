import BaseCollection from '@/app/base/collections/BaseCollection';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import WorksiteProfitabilityDocument from '../models/WorksiteProfitabilityDocument';

export default class WorksiteProfitabilityDocumentCollection extends BaseCollection<WorksiteProfitabilityDocument> {
    model: ModelType = ModelType.WORKSITE_PROFITABILITY_DOCUMENTS;

    byDocumentType(documentType: string): WorksiteProfitabilityDocument[] {
        return this.filter((d: WorksiteProfitabilityDocument) => d.documentType === documentType);
    }

    getTotalFor(callback: CallableFunction): number {
        return this.filter(callback).reduce((total: number, d: WorksiteProfitabilityDocument) => total + d.amount, 0);
    }

    get maximumTotal(): number {
        const totals = {
            budget: this.byDocumentType('quote').reduce((total: number, d: WorksiteProfitabilityDocument) => total + d.total, 0),
        };
    }
}
