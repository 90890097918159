import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class BankAccountAuthorization extends BaseModel {
    type: ModelType = ModelType.BANK_ACCOUNT_AUTHORIZATIONS;
    static type: ModelType = ModelType.BANK_ACCOUNT_AUTHORIZATIONS;
    prefix = 'bankaccount:bank-account-authorizations';
    static prefix = 'bankaccount:bank-account-authorizations';

    get url(): string {
        return this.attributes.url;
    }
}
