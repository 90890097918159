import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type TaskRelationships = {
    lead: ToOneRelationship<ModelType.LEADS>;
    contact: ToOneRelationship<ModelType.CONTACTS>;
    worksite: ToOneRelationship<ModelType.WORKSITES>;
    worksitePhase: ToOneRelationship<ModelType.WORKSITE_PHASES>;
    createdBy: ToOneRelationship<ModelType.USERS>;
    completedBy: ToOneRelationship<ModelType.USERS>;
    timeActivities: ToManyRelationship<ModelType.TIME_ACTIVITIES>;
    users: ToManyRelationship<ModelType.USERS>;
};

type TaskAttributes = {
    title: string;
    description: string | null;
    completedAt: string | null;
    dueDate: string | null;
    options: Record<string, any> | null;
};

type TaskMeta = {};

export default class TaskSchema extends BaseSchema<TaskAttributes, TaskMeta, TaskRelationships> {
    protected type: ModelType = ModelType.TASKS;
}
