import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class TermsOfServiceAcceptation extends BaseModel {
    type: ModelType = ModelType.TERMS_OF_SERVICE_ACCEPTATIONS;
    static type: ModelType = ModelType.TERMS_OF_SERVICE_ACCEPTATIONS;
    prefix: string = 'common:terms-of-service-acceptations';
    static prefix: string = 'common:terms-of-service-acceptations';

    get ipAddress(): string {
        return this.attributes.ipAddress;
    }

    get userAgent(): string {
        return this.attributes.userAgent;
    }

    get locale(): string {
        return this.attributes.locale;
    }

    get location(): object {
        return this.attributes.location;
    }

    get device(): object {
        return this.attributes.device;
    }
}
