import User from '~~/app/auth/models/User';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import Contact from '~~/app/contact/models/Contact';
import CalendarEventCollection from '~~/app/planning/collections/CalendarEventCollection';
import Worksite from '~~/app/worksite/models/Worksite';
import moment from '~~/utils/moment';
import PdfStatus from '../enums/PdfStatus';
import PdfImageCollection from '../collections/PdfImageCollection';

export default class Pdf extends BaseModel {
    type: ModelType = ModelType.PDFS;
    static type: ModelType = ModelType.PDFS;
    prefix: string = 'pdf';
    static prefix: string = 'pdf';

    get pdfImages(): PdfImageCollection | null {
        return this.relationships?.pdfImages?.data;
    }

    get url(): string {
        return this.attributes.url;
    }
}
