import { RawPreviewProduct } from '../classes/ProductPreview';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class ImportedFile extends BaseModel {
    type: ModelType = ModelType.IMPORTED_FILE;
    static type: ModelType = ModelType.IMPORTED_FILE;
    prefix = 'common:files';
    static prefix = 'common:files';

    get url(): string {
        return this.attributes.url;
    }

    get modelType(): string {
        return this.attributes.type;
    }

    get actionType(): string {
        return this.attributes.actionType;
    }

    get status(): string {
        return this.attributes.status;
    }

    get userId(): string {
        return this.attributes.user_id;
    }

    get lines(): Array<RawPreviewProduct> | null {
        return this.meta.preview;
    }

    getDownloadUrl(): string | null {
        return this.url;
    }
}
