import { Meta } from 'zhead';
import InvoicePaymentCollection from '../collections/InvoicePaymentCollection';
import InvoicePayment from '../models/InvoicePayment';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';
import Worksite from '~~/app/worksite/models/Worksite';

export default class InvoicePaymentEndpoint extends BaseEndpoint<InvoicePayment, InvoicePaymentCollection> {
    model: ModelType = ModelType.INVOICE_PAYMENTS;
    path = 'tenant/invoicing/invoice-payments';
    include = 'invoice,bankAccountTransaction';

    /**
     * We want to refresh the invoice's data after a payment is created, updated or deleted.
     * Because the totalDue, totalPaid and status might have changed.
     */
    onEventSentCallback: Function | null = (event: string, model: InvoicePayment) => {
        if (model.invoice) {
            useEvent('invoicing:invoice:updated', model.invoice);
        }
    };
}
