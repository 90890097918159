import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import moment from '~~/utils/moment';

export default class NotificationLog extends BaseModel {
    type: ModelType = ModelType.NOTIFICATIONS_LOGS;
    static type: ModelType = ModelType.NOTIFICATIONS_LOGS;
    prefix = 'common:notifications-logs';
    static prefix = 'common:notifications-logs';

    get driver(): string {
        return this.attributes.driver;
    }

    get status(): string {
        return this.attributes.status;
    }

    get recipient(): string {
        return this.attributes.recipient;
    }

    get subject(): string {
        return this.attributes.subject;
    }

    get content(): string {
        return this.attributes.content;
    }

    get previewText(): string {
        return this.attributes.preview;
    }

    get failure(): string | Record<string, any> {
        return this.attributes.failure;
    }
}
