import { useToasteo } from '@deegital/vue-trustup-io-toasteo';

export default function useToasteoSuccess(translationKeyOrText: string = 'toasts.updated', raw: boolean = false) {
    const { t } = useTranslation();
    const toasteo = useToasteo();

    return toasteo.success({
        text: raw ? translationKeyOrText : t(translationKeyOrText),
    });
}
